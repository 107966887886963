import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserFriends, faCopy, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import UsageGraph from '../usage-graph';

import './plan-summary.css';

const ResourceDetail = ({ allowed, used, title }) => {
  if (allowed < 1) {
    return <div className="plan-summary__section__text">
      {used} {title}
    </div>;
  }

  return <>
    <UsageGraph used={used} allowed={allowed} />
    <div className="plan-summary__section__text">
      {used} reports out of {allowed}
    </div>
  </>;
};

const PlanDetail = ({ plan, billingPeriod }) => {
  return <div className="plan-summary__section">
    <div className="plan-summary__section__icon" style={{width: '50px'}}>
      <FontAwesomeIcon icon={faInfoCircle} />
    </div>
    <div className="plan-summary__section__detail">
      Your plan is billed {plan.billingPeriod}, your usage will be reset on your next billing date {billingPeriod.billingDate}.
    </div>
  </div>
}

const ResourceSummary = ({ icon, title, property, plan, usage }) => <div className="plan-summary__section">
  <div className="plan-summary__section__icon">
    <FontAwesomeIcon icon={icon} />
  </div>
  <div className="plan-summary__section__graph">
    <ResourceDetail title={title} allowed={plan[property] || 0} used={usage[property] || 0} />
  </div>
</div>;

export default ({ planSummary }) => <>
  <ResourceSummary icon={faUserFriends} title="team members" property="teamMembers" {...planSummary} /> 
  <ResourceSummary icon={faCopy} title="reports" property="reports" {...planSummary} /> 
  <PlanDetail plan={planSummary.plan} billingPeriod={planSummary.billingPeriod} />
</>;
