import React, { useState } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import SubmitButton from '../button/submit-button';
import DialogModal from './';

const Error = ({ error }) => {
  return <div className="dialog-modal__error">
    <div className="dialog-modal__error__icon"><FontAwesomeIcon icon={faExclamationTriangle} /></div>
    <div className="dialog-modal__error__text">
      Failed to contact server - please try again<br/>
      If the problem persists please contact support.
    </div>
  </div>;
};

const ConfirmActionDialog = ({ title, icon, submitButton, onSubmit, children, onClose, autoClose = true }) => {
  const [buttonIsSubmitting, setButtonSubmitting] = useState(false);
  const [error, setError] = useState();

  const closeDialog = () => {
    if (onClose) {
      onClose();
    }
  };

  const titleElement = <>
    <FontAwesomeIcon icon={icon} />&nbsp;{title}
  </>;

  const buttons = [
    <SubmitButton {...submitButton} submitted={buttonIsSubmitting} onClick={async () => {
      if (buttonIsSubmitting) {
        return;
      }

      setButtonSubmitting(true);

      if (onSubmit) {
        try {
          await onSubmit();
        } catch (e) {
          setError({ message: e.message, code: e.code });
          setButtonSubmitting(false);
        }
      }

      if (autoClose) {
        closeDialog();
      }
    }}>Submit</SubmitButton>,
    { text: 'Cancel', action: 'close' },
  ];

  return <>
    <DialogModal open={true} onClose={closeDialog} content={<>
      {error ? <Error error={error} /> : <></>}
      {children}
    </>} buttons={buttons} title={titleElement} />
  </>;
}

export default ConfirmActionDialog;
