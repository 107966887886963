import React from 'react';

import { faUserSlash, faKey, faUserCheck, faTrash, faEnvelope, faCrown } from '@fortawesome/free-solid-svg-icons';
import { API } from 'aws-amplify';
import { useHistory } from 'react-router-dom';

import DialogManager from '../modal/dialog-manager';
import ConfirmModal from '../modal/confirm-action';

export default ({ userInfo: { firstName, username, email }, refresh, ...dialogOptions }) => {
  const history = useHistory();
  const dialogs = {
    delete: {
      title: `Delete Invitation`,
      icon: faTrash,
      submit: {
        apiEndpoint: `user/${username}`,
        apiMethod: 'del',
        refresh: false,
        redirect: '/team/invitations',
        button: {
          text: 'Delete',
          type: 'destructive',
        },
      },
      content: <div>
        Delete invitation for {email}?
        <br />  
        <br />
        You can reinvite them again if required, any existing temporary password already email will not work.
      </div>
    },
    deactivate: {
      title: `Deactivate ${firstName}`,
      icon: faUserSlash,
      submit: {
        apiEndpoint: `user/${username}/deactivate`,
        refresh: true,
        button: {
          text: 'Deactivate',
          type: 'destructive',
        },
      },
      content: <div>
        Deactivating a user will prevent them from logging into their account.
        <br />  
        <br />
        Deactivated users can be reactivated at any time.  
      </div>
    },
    reactivate: {
      title: `Reactivate ${firstName}`,
      icon: faUserCheck,
      submit: {
        apiEndpoint: `user/${username}/reactivate`,
        refresh: true,
        button: 'Reactivate',
      },
      content: <div>
        Reactivating a user will re-enable their access, their role will default to inspector, rather than their previous access.
        <br />
        <br />
        Their password will also be reset, they will have 1 hour to login and use this code to set a new password.
      </div>,
    },
    resetPassword: {
      title: `Reset ${firstName}'s Password`,
      icon: faKey,
      submit: {
        apiEndpoint: `user/${username}/resetPassword`,
        button: 'Reset',
      },
      content: <div>
        We will send {firstName} an email with reset code, they will need to login within 1hour using any password, then use the supplied code to set a new password.
        <br />
        <br />
        After 7 days, reset password will need to be used again.
      </div>,
    },
    resendInvitation: {
      title: 'Resend Invitation',
      icon: faEnvelope,
      submit: {
        apiEndpoint: `user/${username}/resend-invitation`,
        refresh: true,
        button: 'Resend Invitation',
      },
      content: <div>
        We will resend an invitation email to {email}. This email will contain a temporary password, valid for 7 days, any previous passwords will be invalidated.
      </div>,
    },
    makeOwner: {
      title: 'Make Owner',
      icon: faCrown,
      submit: {
        apiEndpoint: `user/${username}/make-owner`,
        refresh: true,
        button: 'Make Owner',
      },
      content: <div>
        Make this user the account owner - account owners have full control of the team and billing.  This will return the current owner to an admin.
      </div>,
    },
  };

  return <DialogManager
    dialogs={Object.fromEntries(Object.entries(dialogs).map(([key, {
      title,
      icon,
      submit,
      content,
    }]) => {
      const submitButton = typeof submit.button === 'string'
        ? { text: submit.button, icon }
        : {
          icon: submit.button.icon || icon,
          type: submit.button.type,
          text: submit.button.text,
        };

      return [key, ({ onClose }) => <ConfirmModal
        title={title}
        icon={icon}
        onClose={onClose}
        autoClose={false}
        onSubmit={async () => {
          await API[submit.apiMethod || 'put']('api', submit.apiEndpoint);

          if (submit.refresh && refresh) {
            refresh();
          }

          if (submit.redirect) {
            return history.push(submit.redirect);
          }

          onClose();
        }}
        submitButton={submitButton}>{content}</ConfirmModal>]
    }))}
    {...dialogOptions}
  />;
};
