import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import './list.css';

const getIconElement = (icon) => {
  if (!icon) {
    return <></>;
  }

  if (icon.iconName) {
    return <div className="list__item__icon"><FontAwesomeIcon icon={icon} /></div>;
  }

  return <div className="list__item__icon">{icon}</div>;
}

const LinkIfRequired = ({ to, children  }) => to ? <Link to={to}>{children}</Link> : children;

export default ({
  icon,
  items = [],
  additionalListClass,
  additionalItemClass,
}) => {
  const defaultIcon = icon;
  const listClass = `list${additionalListClass ? ` ${additionalListClass}` : ''}`;
  const itemClass = `list__item${additionalItemClass ? ` ${additionalItemClass}` : ''}`;

  return <ul className={listClass}>
    {items.map(({
      key,
      icon = defaultIcon,
      title,
      link,
      content,
      className,
    }) => {
      const iconElement = getIconElement(icon);
      const titleElement = title ? <div className="list__item__title">{title}</div> : <></>;

      return <li className={`${itemClass}${className ? ` ${className}` : ''}${icon ? ' with-icon' : ''}`} key={key}>
        <LinkIfRequired to={link}>
          {iconElement}
          {titleElement}
          {content}
        </LinkIfRequired>
      </li>;
    })}
  </ul>;
};
