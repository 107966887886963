import React from 'react';

import { faUser, faUserTie, faUserCog, faUserSlash, faEnvelope, faCrown } from '@fortawesome/free-solid-svg-icons';
import { faOldRepublic } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const userRoleIcons = {
  invited: faEnvelope,
  deactivated: faUserSlash,
  inspector: faUser,
  'team-lead': faUserTie,
  admin: faUserCog,
  owner: faCrown,
  'system-admin': faOldRepublic,
};

export default ({ role }) => <FontAwesomeIcon icon={userRoleIcons[role] || faUser} />;
