import React, { useState } from 'react';
import { Tooltip } from 'react-tippy';

import 'react-tippy/dist/tippy.css'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const selectFieldText = e => e.target.select();

const MatrixCell = ({
  value,
  valueChanged,
  name,
  column,
  row,
  table,
  tabIndex,
  tooltip,
  showTooltip,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const onFocus = (e) => {
    if (!tooltip) {
      return;
    }

    const previousSelectedFields = document.querySelectorAll('.selected-foundations-field');
    previousSelectedFields.forEach(field => field.classList.remove('selected-foundations-field'));
    e.target.classList.add('selected-foundations-field');
    setTooltipOpen(true);
  };

  const onBlur = (e) => {
    if (!tooltip) {
      return;
    }

    setTooltipOpen(false);
  };

  const id=`${table}-${column}-${row}`;

  const fieldUpdated = ({target}) => valueChanged({ row, column, value: target.value });

  return <Tooltip
    open={tooltipOpen}
    disabled={!showTooltip}
    html={tooltip}
    size="big"
    interactive
    position="top"
    trigger="focus click">
    <input type="number"
      id={id}
      className="edit-report__matrix__table-field"
      onChange={fieldUpdated}
      onClick={selectFieldText}
      onFocus={onFocus}
      onBlur={onBlur}
      name={name}
      value={value}
      data-p={column}
      data-r={row}
      data-table={table}
      tabIndex={tabIndex}
      min="0"
    />
  </Tooltip>;
};

const ErrorMessage = ({ errors }) => {
  if (!errors.length) {
    return <></>;
  }

  return <div className="edit-report__matrix__error">
    <div className="edit-report__matrix__error__icon">
      <FontAwesomeIcon icon={faExclamationTriangle} />
    </div>
    <div className="edit-report__matrix__error__text">
      {errors.map(([name, value], i) => <div key={`${name}_error_${i}`}>{value}</div>)}
    </div>
  </div>;
};

const getErrorsForMatrix = (errors, name) => Object.entries(errors).filter(([fieldName]) => fieldName.startsWith(name));

const MatrixTable = ({
  name,
  rows,
  columns,
  report,
  onValueChange,
  showTooltip = false,
  tooltipTitle,
  tabIndexMultiplier = 1,
  errors = {},
  validateForm,
}) => {
  const id = `edit-report__table__${name}`;

  const rowItems = Array(rows).fill(Array(columns).fill(undefined).map(() => 0.0));
  const headers = Array(columns).fill(undefined).map((x, i) => `P${i + 1}`);

  const getNextField = (table, r, p) => {
    if (r === 7 && p === 3) {
      return;
    }

    if(r === 7) {
      return `${table}-${p + 1}-0`;
    }

    return `${table}-${p}-${r + 1}`;
  };

  let tooltipSetValue = (e) => {
    const currentField = document.querySelector('.selected-foundations-field');
    currentField.value = e.target.innerText;
    const r = parseInt(currentField.dataset.r, 10);
    const p = parseInt(currentField.dataset.p, 10);
    const table = currentField.dataset.table;
    const nextField = getNextField(table, r, p);

    if(nextField) {
      const nextFieldElement = document.getElementById(nextField);
      nextFieldElement.focus();
      nextFieldElement.select();
    }

    onValueChange({ column: p, row: r, value: e.target.innerText });
  };
  
  const buildTooltip = (name, values) => {
    const all = rowItems.map((reading, rowI) => reading.map((point, cellI) => {
      return values[`${name}[${cellI}][${rowI}]`];
    })).flat();

    const tooltipOptions = [
      ...new Set(all
        .filter(v => v !== '' && !isNaN(v))
        .map(v => parseFloat(v, 10)))
    ];

    return <div>
      {tooltipTitle ? <>{tooltipTitle}<br/ ></> : <></>}
      {tooltipOptions.map(value => <div key={value} className="tooltip-foundation-option" onClick={tooltipSetValue}>{value}</div>)}
    </div>;
  }

  const tooltip = buildTooltip(name, report);

  return <>
    <table id={id}>
      <tbody>
        <tr>
            <td className="edit-report__matrix__table-cell">&nbsp;</td>
            {headers.map((header, i) => <th
              key={`${name}-header-${i}`}
              className="edit-report__matrix__table-cell">{header}</th>)}
        </tr>
        {rowItems.map((reading, rowI) => {
          const row = rowI + 1;
          const key = `${name}-row-${rowI}`;
          return <tr key={key}>
            <th className="edit-report__matrix__table-cell">R{row}</th>
            {reading.map((point, cellI) => {
              const cell = cellI + 1;
              const tabIndex = tabIndexMultiplier * ((row) + (cell * 10));
              const cellKey = `${key}-cell-${cellI}`;
              const cellName = `${name}-R${row}-P${cell}`;

              return <td key={cellKey} className="edit-report__matrix__table-cell">
                <MatrixCell
                  value={report[`${name}[${cellI}][${rowI}]`]}
                  valueChanged={onValueChange}
                  name={cellName}
                  column={cellI}
                  row={rowI}
                  table={name}
                  tabIndex={tabIndex}
                  tooltip={tooltip}
                  showTooltip={showTooltip}
                />
              </td>
            })}
          </tr>;
        })}
      </tbody>
    </table>
    <ErrorMessage errors={getErrorsForMatrix(errors, name)} />
  </>;
};

export default ({ index = 1, title, onChange, ...props }) => <div className="edit-report__matrix">
  {title ? <h3>{title}</h3> : <></>}
  <MatrixTable
    {...props}
    tabIndexMultiplier={index * Math.pow(10, index)}
    onValueChange={(v) => onChange({ name: `${props.name}[${v.column}][${v.row}]`, value: v.value })}
  />
</div>;
