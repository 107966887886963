import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faRuler, faFileMedical, faHome, faListUl, faUsersCog, faUser } from '@fortawesome/free-solid-svg-icons';
import { Auth } from "aws-amplify";
import { slide as Menu } from 'react-burger-menu';
import { Link } from 'react-router-dom';

import './menu.css';

const baseMenuOptions = [
  { link: '/', icon: faHome, text: 'Home' },
  { link: '/report/new', icon: faFileMedical, text: 'New Report' },
];

const roleMenuOptions = {
  'owner': [
    { link: '/reports', icon: faListUl, text: 'View Reports' },
    { link: '/team', icon: faUsersCog, text: 'Manage Team' },
  ],
  'admin': [
    { link: '/reports', icon: faListUl, text: 'View Reports' },
    { link: '/team', icon: faUsersCog, text: 'Manage Team' },
  ],
  'inspector': [
    { link: '/reports', icon: faListUl, text: 'My Reports' },
  ],
};

const getMenuItemsForRole = ({ userRole, teamId }) => [
  ...baseMenuOptions,
  ...(roleMenuOptions[userRole] || []),
];

export default ({ userContext }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const closeMenu = () => setMenuOpen(false);
  const signout = () => Auth.signOut();

  return <Menu isOpen={menuOpen} onStateChange={({ isOpen }) => setMenuOpen(isOpen)}>
    <div className="top-bar__menu__user">
      <div className="top-bar__menu__user__icon">
        <span><FontAwesomeIcon icon={faRuler} /></span>
      </div>
      <div className="top-bar__menu__user__details">
        <div className="top-bar__menu__user__details__user">{userContext.firstName} {userContext.lastName}</div>
        <div className="top-bar__menu__user__details__team">{userContext.teamName}</div>
        <div className="top-bar__menu__user__details__options">
          <Link to="/profile" onClick={closeMenu}><FontAwesomeIcon icon={faUser} /> Profile</Link>
          &nbsp;&nbsp;
          <span className="top-bar__menu__user__details__options__signout" onClick={signout}><FontAwesomeIcon icon={faSignOutAlt} /> Sign Out</span>
        </div>
      </div>
    </div>
    <ul className="top-bar__menu__list">
      {getMenuItemsForRole(userContext).map(({ link, icon, text }, i) => {
        const key = `menu__item__${i}`;

        return <li className="top-bar__menu__item" key={key}>
          <Link to={link} onClick={closeMenu}>
            <div className="top-bar__menu__item__icon"><FontAwesomeIcon icon={icon} /></div>
            <div className="top-bar__menu__item__text">{text}</div>
          </Link>
        </li>
      })}
    </ul>
  </Menu>;
};
