import React from 'react';

import './usage-graph.css';

export default ({ used, allowed }) => {
  const percentageUsed = Math.ceil((used / allowed) * 100);
  const graphWidth = `${percentageUsed > 100 ? 100 : percentageUsed}%`;

  return <div className="usage-graph__outer">
    <div className="usage-graph__inner" style={{ width: graphWidth }}></div>
  </div>;
};
