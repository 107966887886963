import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useWindowScroll } from 'react-use';

import './toast-bar.css';

const ToastBar = ({ onClose = () => {}, children }) => {
  const { y } = useWindowScroll();

  return <div className="toast-bar" style={{ top: `${(52 - y < 0 ? 0 : 52 - y)}px` }}>
    <div className="toast-bar__message">
      {children}
    </div>
    <div className="toast-bar__close">
      <FontAwesomeIcon icon={faTimesCircle} onClick={onClose} />
    </div>
  </div>;
};

export default ({ open = false, events: { onClose } = {}, children }) => {
  if (!open) {
    return <></>;
  }

  return <ToastBar onClose={onClose}>{children}</ToastBar>;
};
