import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './tabs.css';

export default ({ tabs, defaultTab, setTab, onChange }) => {
  const locationSelectedTab = defaultTab;

  const [currentTab, setCurrentTab] = useState(locationSelectedTab || defaultTab);

  if (setTab) {
    setTab(setCurrentTab);
  }

  const [selectedTab] = tabs.filter(({ id, title }) => (id || title.toLowerCase()) === currentTab);
  onChange(currentTab);

  return <div className="tabs__panel">
    <ul className="tabs__panel__tab-strip">
      <li className="tabs__panel__tab-strip__pre"></li>
      {tabs.map(({ title, icon, id = title.toLowerCase() }, i) => <li
        key={`tab__panel__${id}`}
        className={`tabs__panel__tab-strip__item${id === currentTab ? ' selected-tab' : ''}`}
        onClick={() => {
          setCurrentTab(id);
          if (onChange) {
            onChange(id);
          }
        }}>
        <div className="tabs__panel__tab-strip__item__icon"><FontAwesomeIcon icon={icon} /></div>
        <div className="tabs__panel__tab-strip__item__title">{title}</div>
      </li>)}
      <li className="tabs__panel__tab-strip__post"></li>
    </ul>
    <div className="tabs__panel__content">
      {selectedTab ? selectedTab.content : <></>}
    </div>
  </div>;
};
