import React from 'react';

import { faFileMedical, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import QuickFunctions from '../../quick-functions';

import './quick-functions.css';

export default ({ userRole }) => <QuickFunctions userRole={userRole} buttons={[
  { icon: faFileMedical, text: 'New Report', link: '/report/new' },
  { icon: faUserPlus, text: 'Invite Team Member', link: '/team/invite', permittedRoles: ['admin', 'owner', 'system-admin'] },
]} />;
