import React from 'react';

import { Line } from 'react-chartjs-2';

const pointConfig = (color) => ({
  pointBorderWidth: 5,
  pointHoverRadius: 5,
  backgroundColor: color,
  borderColor: color,
  pointBorderColor: color,
  pointBackgroundColor: color,
  pointHoverBackgroundColor: color,
  pointHoverBorderColor: color,
  pointRadius: 1,
  pointHitRadius: 10,
  fill: false,
});

const getAllValues = (visualisations) => {
  return visualisations.reduce((all, visualisation) => {
    const { data, value } = visualisation;

    if (typeof data !== 'undefined') {
      return [...all, ...data];
    }

    if (typeof value !== 'undefined') {
      return [...all, value];
    }

    return all;
  }, []);
};

const getMin = (values) => values
  .reduce((minValue, currentValue) => currentValue < minValue
    ? currentValue
    : minValue, 0);

const getMax = (values) => values
  .reduce((maxValue, currentValue) => currentValue > maxValue
    ? currentValue
    : maxValue, 0);

const getMinMax = (visualisations) => {
  const values = getAllValues(visualisations)
    .filter(value => !isNaN(value))
    .map(value => parseFloat(value));

  return {
    min: getMin(values),
    max: getMax(values),
  };
};

export default () => {
  let labels = [];
  const visualisations = [];

  const getData = () => ({
    labels,
    datasets: visualisations.reduce((all, current) => [
      ...all,
      ...current.toDatasets(),
    ], []),
  });
  
  const getOptions = () => {
    const { min, max } = getMinMax(visualisations);

    return {
      height: 500,
      maintainAspectRatio: false,
      animation: {
        duration: 0,
      },
      hover: {
        animationDuration: 0,
      },
      responsiveAnimationDuration: 0,
      scales: {
        yAxes:[
          {
            id: 'y-axis',
            type: 'linear',
            ticks: {
              min: min - (min < 0 ? 3 : 0),
              max: max + 3,
            },
          },
        ],
      },
    };
  };

  const visualisationApi = {
    setLabels: newLabels => {
      labels = newLabels;

      return visualisationApi;
    },
    addScatterPoints: (data, options) => {
      visualisations.push({
        data,
        options,
        toDatasets: () => [
          {
            data,
            fill: false,
            showLine: false,
            ...pointConfig(options.color),
            ...options,
          }
        ]
      });

      return visualisationApi;
    },
    addHorizontalLine: (value, options) => {
      visualisations.push({
        value,
        options,
        toDatasets: () => {
          const data = new Array(labels.length).map(() => value);

          data[0] = value;
          data[labels.length - 1] = value;

          return [
            {
              fill: false,
              spanGaps: true,
              lineTension: 0.1,
              ...pointConfig(options.color),
              ...options,
              data,
            }
          ];
        },
      });

      return visualisationApi;
    },
    addShadedArea: ({ upper, lower }, { label, ...options }) => {
      visualisations.push({
        value: upper,
        options,
        toDatasets: () => {
          const data = new Array(labels.length).map(() => upper);

          data[0] = upper;
          data[labels.length - 1] = upper;

          return [
            {
              spanGaps: true,
              lineTension: 0.1,
              label: `${label} Upper`,
              ...pointConfig(options.color),
              ...options,
              data,
            }
          ];
        },
      });

      visualisations.push({
        value: lower,
        options,
        toDatasets: () => {
          const data = new Array(labels.length).map(() => lower);

          data[0] = lower;
          data[labels.length - 1] = lower;

          return [
            {
              spanGaps: true,
              lineTension: 0.1,
              label: `${label} Lower`,
              ...pointConfig(options.color),
              ...options,
              fill: "-1",
              data,
            }
          ];
        },
      });

      return visualisationApi;
    },
    getMinMax,
    convertToDatasets: () => visualisations.reduce((all, current) => [
      ...all,
      ...current.toDatasets(),
    ], []),
    getLabels: () => labels,
    createChart: ({ height = 300 }) => <Line data={getData()} height={height} options={getOptions()} />
  };

  return visualisationApi;
};
