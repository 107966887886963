import React, { useState } from 'react';

import './descriptive-selector.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCheckCircle } from '@fortawesome/free-regular-svg-icons';


const DescriptiveSelectorItem = ({ name, value, icon, title, text, isSelected=false, onClick }) => <li className={`descriptive-selector__item${isSelected ? ' descriptive-selector__item-selected' : ''}`}>
  <label className="descriptive-selector__label" onClick={onClick}>
    <div className="descriptive-selector__checkbox"><FontAwesomeIcon icon ={isSelected ? faCheckCircle : faCircle} /></div>
    <input type="radio" name={name} value={value} {...(isSelected ? { selected: 'selected' } : {})} />
    <div className="descriptive-selector__label__icon">
      {icon}
    </div>

    <div>
      <div className="descriptive-selector__label__title">{title}</div>
      {text}
    </div>
  </label>
</li>;

export default ({ values = [], defaultValue = '', onChange = () => {} }) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const valueUpdated = (newValue) => {
    setSelectedValue(newValue);

    if (onChange) {
      onChange(newValue);
    }
  };

  return <ul className="descriptive-selector">
    {values.map(({ icon, value, title, text }) => <DescriptiveSelectorItem
      isSelected={selectedValue === value}
      icon={icon}
      value={value}
      title={title}
      text={text}
      onClick={(e) => valueUpdated(e.target.value)}
    />)}
  </ul>;
};