import React, { useState, useEffect } from 'react';

import { DateTime } from 'luxon';
import { Redirect } from 'react-router-dom';
import { faLevelUpAlt } from '@fortawesome/free-solid-svg-icons';
import { API } from "aws-amplify";
import ResizeDetector from 'react-resize-detector';

import Loading from '../../components/loading';
import useUserContext from '../../user-context';
import ResponsiveList from '../../components/responsive-list';
import QuickFunctions from '../../components/quick-functions';
import UsageGraph from '../../components/usage-graph';

import './usage.css';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const periodClasses = [
  'var(--color-blue)',
  '#C78903',
  '#7BD167',
  'yellow',
  '#9682D1',
  '#29842E',
];

const WeeklyUsageGraph = ({ usage, billingPeriodColours, width }) => {
  if (width < 900) {
    return <></>;
  }

  const remainingItems = [...usage.billingPeriods].reverse();
  let current;
  let currentContainer;
  let currentMonth;

  const months = [];

  while ((current = remainingItems.shift())) {
    const currentDate = DateTime.fromISO(current.date);
    const startOfWeek = currentDate.minus({ days: currentDate.weekday - 1 });
    const month = startOfWeek.toISODate().substring(0, 7);
    
    if (!currentMonth || currentMonth.month !== month) {
      currentMonth = {
        columns: [],
        month,
        label: `${startOfWeek.monthShort}`,
        yearLabel: !currentMonth || startOfWeek.month === 1 ? `'${startOfWeek.year.toString().substring(2)}` : ''
      };
      months.push(currentMonth);
    }

    if (!currentContainer || currentContainer.start.valueOf() !== startOfWeek.valueOf()) {
      currentContainer = { segments: [], start: startOfWeek, total: 0 };
      currentMonth.columns.push(currentContainer);
    }

    currentContainer.total += current.reports;
    currentContainer.segments.push({
      ...current,
      colour: billingPeriodColours[current.billingPeriodStart],
    });
  }

  const max = months.reduce((max, month) => {
    const monthMax = month.columns.reduce((columnMax, column) => column.total > columnMax ? column.total : columnMax, 0);

    return monthMax > max ? monthMax : max;
  }, 0);

  const graphUnit = Math.floor(240 / max);

  return <div className="weekly-usage__container">
    {months.map(({ columns, yearLabel, label }, i) => <div className={`weekly-usage__month${i === months.length - 1 ? ' weekly-usage__month-last' : ''}`} style={{flexGrow: columns.length}}>
      <div className="weekly-usage__month__weeks">
        {columns.map(({ segments, start, total }) => <div className="weekly-usage__week" title={`${start.toISODate()} - ${total} reports`}>
          {segments.map(({ colour, reports }) => <div
            className="weekly-usage__week__segment"
            style={{ height: `${graphUnit * reports}px`, backgroundColor: colour }}> </div>)}
        </div>)}
      </div>
      <div className="weekly-usage__month__label">
        {columns.length > 2 ? <>{label}<br />{yearLabel}</> : <></>}
      </div>
    </div>)}
  </div>;
};

const UsageList = ({ usage, plan, billingPeriodColours }) => <>
  <ResponsiveList
    showTopBar={false}
    items={usage.usage.monthly.billingPeriods.map(({ key, date, reports }) => ({
      key: key,
      date,
      reports,
      icon: <FontAwesomeIcon icon={faCircle} />,
      iconColour: billingPeriodColours[date] || '#ddd',
    }))}
    columns={[
      { header: 'Date', isHeader: true, width: 200, value: 'date' },
      { header: 'Report Graph', isHeader: true, width: 300, value: ({ reports }) => <UsageGraph used={reports} allowed={plan.reports} /> },
      { header: 'Reports', isHeader: true, width: 200, value: ({ reports }) => `${reports} reports` },
    ]}
    itemName="month"
  />
</>;

const PlanDetail = ({ teamId }) => {
  const [data, updateData] = useState();
  const [loading, setLoading] = useState(true);
  
  function useFetch(url) {
    useEffect(() => {
      const getData = async () => {
        const resp = await API.get('api', url);
        updateData(resp);
        setLoading(false);
      };
      getData();
    }, [url]);

    return data;
  }

  const usage = useFetch(`team/${teamId}/usage/detail`);

  if (loading) {
    return <Loading />;
  }

  const billingPeriodColours = [...usage.usage.monthly.billingPeriods].reverse().reduce((periods, { reports, date }) => {
    if (reports) {
      periods[date] = periodClasses[(Object.keys(periods).length % periodClasses.length)];
    }

    return periods;
  }, {});

  return <>
    <ResizeDetector handleWidth refreshMode="throttle" refreshRate={250}>
      <WeeklyUsageGraph usage={usage.usage.weekly} billingPeriodColours={billingPeriodColours} />
    </ResizeDetector>
    <div className="usage__list__container">
      <UsageList usage={usage} plan={usage.plan} billingPeriodColours={billingPeriodColours} />
    </div>
  </>;
};

export default () => {
  const userContext = useUserContext();

  if(!userContext || !userContext.teamId) {
    return <></>;
  }

  if(!['admin', 'owner', 'system-admin'].includes(userContext.userRole)) {
    return <Redirect to="/403" />;
  }

  return <>
    <h2>Plan Usage</h2>

    <QuickFunctions buttons={[
      { icon: faLevelUpAlt, text: 'Back to Team Management', link: '/team' },
    ]} />

    <PlanDetail teamId={userContext.teamId} />
  </>;
};
