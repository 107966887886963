import React from 'react';

import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faMapMarkerAlt, faUser, faClipboardCheck } from '@fortawesome/free-solid-svg-icons';

import './summary.css';

export default ({ report }) => <div className="view-report__summary">
  <div className="view-report__summary__section">
    <div className="view-report__summary__section__item">
      <div className="view-report__summary__section__item__icon"><FontAwesomeIcon icon={faCalendar} /></div>
      <div className="view-report__summary__section__item__text">{DateTime.fromISO(report.inspectionDate).toLocaleString()}</div>
    </div>
    
    <div className="view-report__summary__section__item">
      <div className="view-report__summary__section__item__icon"><FontAwesomeIcon icon={faUser} /></div>
      <div className="view-report__summary__section__item__text">{report.userFullName}</div>
    </div>
  </div>

  <div className="view-report__summary__section">
    <div className="view-report__summary__section__item">
      <div className="view-report__summary__section__item__icon"><FontAwesomeIcon icon={faMapMarkerAlt} /></div>
      <div className="view-report__summary__section__item__text">
        {report.address}<br/>
        {report.zip}
      </div>
    </div>
  </div>
  
  <div className="view-report__summary__section">
    <div className="view-report__summary__section__item">
      <div className="view-report__summary__section__item__icon"><FontAwesomeIcon icon={faClipboardCheck} /></div>
      <div className="view-report__summary__section__item__text">
        No Review
      </div>
    </div>
  </div>
</div>;
