import { useState, useEffect } from 'react';
import { Auth } from "aws-amplify";

function useUserContext() {
  const [data, updateData] = useState({});

  const getData = async () => {
    const session = await Auth.currentSession();
    updateData(session.idToken.payload);
  };

  useEffect(() => {
    getData();
  }, []);

  return data;
}

export default useUserContext;
