import React from 'react';

import FoundationGraph from './foundation-graph';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import './foundations.css';

const FoundationDiscrepency = ({ section: { pass = true } }) => pass ? <></> : <div className="discrepency__section">
    <div className="discrepency__section__icon">
    <FontAwesomeIcon icon={faExclamationTriangle} />
  </div>
  <div className="discrepency__section__text">
    The foundation survey revealed that the level of the foundation varied by 1.5" or more. The inspector recommends that you seek a professional engineer's opinion with expertise in residential foundations for advice regarding any repair or not of this slab.
  </div>
</div>;

const MatrixTable = ({ dataset }) => {
  if (!dataset.length) {
    return <div>No Recordings</div>;
  }

  const columns = Array(dataset.length).fill(undefined).map((item, i) => i + 1);
  const rows = Array(dataset[0].length).fill(undefined);

  return <table className="foundations__matrix-table">
    <thead>
      <tr>
        <td className="foundations__matrix-table__cell"></td>
        {columns.map(column => <th className="foundations__matrix-table__cell">P{column}</th>)}
      </tr>
    </thead>
    <tbody>
      {rows.map((row, rowNumber) => <tr>
        <th className="foundations__matrix-table__cell">P{rowNumber + 1}</th>
        {columns.map((column, i) => <td className="foundations__matrix-table__cell">{dataset[i][rowNumber] ? dataset[i][rowNumber].toFixed(1) : '0.0'}</td>)}
      </tr>)}
    </tbody>
  </table>;
};

const SurfaceGraph = ({ measurements, width, reference }) => {
  if (typeof width === 'undefined') {
    return <></>;
  }

  const graphHeight = width > 900 ? 440 : width > 500 ? 300 : 200;
  
  return <div className="foundations__graph-container" style={{ height: `${graphHeight}px` }}>
    <FoundationGraph measurements={measurements} reference={reference} options={{ height: `${graphHeight}px` }} />
  </div>;
};

export default ({ report, width }) => {
  const { foundations } = report;

  if (!foundations) {
    return <div>
      <h2>Section not completed</h2>
    </div>;
  }

  return <>
    <FoundationDiscrepency section={foundations} />
    <SurfaceGraph measurements={foundations.tableOfMeasurements} width={width} reference={report.reference} />
    <div className="foundations__container">
      <div className="foundations__table-container">
        <h2>Table of Measurements</h2>
        <MatrixTable dataset={foundations.tableOfMeasurements} />
      </div>

      <div className="foundations__table-container">
        <h2>Table of Readings</h2>
        <MatrixTable dataset={foundations.tableOfReadings} />
      </div>
      
      <div className="foundations__table-container">
        <h2>Table of Surfaces</h2>
        <MatrixTable dataset={foundations.tableOfSurfaces} />
      </div>
    </div>
  </>;
};
