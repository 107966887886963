import React from 'react';

import { API, Auth } from "aws-amplify";
import { Formik, Form } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRuler, faCheck } from '@fortawesome/free-solid-svg-icons';

import FormField from '../../components/form/form-field';
import StatusMessage from '../../components/form/status-message';
import SubmitButton from '../../components/button/submit-button';
import useUserContext from '../../user-context';

import './first-time.css';

export default () => {
  const userContext = useUserContext();

  if (!userContext) {
    return <></>;
  }

  return <div className="first-time__container">
    <div className="first-time__icon">
      <FontAwesomeIcon icon={faRuler} />
    </div>
    <h2>Welcome!</h2>
    <p className="first-time__text">
      Welcome to Measure It Now! You've been invited to join the team <b>{userContext.teamName}</b>. To get started enter your name below and hit "Next".  If you think you've been given access in error then pleast contact Support.
    </p>
    <Formik
        initialValues={{
          firstName: '',
          lastName: '',
        }}
        validate={({ firstName, lastName }) => {
          let errors = {};

          if (!firstName) {
            errors.firstName = 'You must enter a first name';
          }
          
          if (!lastName) {
            errors.lastName = 'You must enter a last name';
          }

          return errors;
        }}
        onSubmit={async (values,
          {
            setSubmitting,
            setValues,
            setStatus,
            setErrors
          }) => {
            setSubmitting(true);

            try {
              await API.put('api', `profile/first-time`, {
                body: values,
              });

              const cognitoUser = await Auth.currentAuthenticatedUser();
              const currentSession = await Auth.currentSession();
              
              cognitoUser.refreshSession(currentSession.refreshToken, () => {
                window.location = '/';
              });
            } catch (e) {
              setSubmitting(false);

              if (e.message === 'Network Error') {
                setStatus({ text: 'Could not contact Server, please try again later', state: 'error' });
                return;
              }

              if (e.response.status === 400) {
                if (!e.response.data || !e.response.data.message) {
                  setStatus({ text: e.message });

                  return;
                }
              }
            }
        }}
        render={({ values, errors, status = {}, touched, isSubmitting }) => {
          return <Form>

              <StatusMessage text={status.text} state={status.state} />
              <div className="first-time__form">
                <FormField
                  label="First Name"
                  errors={errors}
                  touched={touched}
                  type="text"
                  name="firstName" />

                <FormField
                  label="Last Name"
                  errors={errors}
                  touched={touched}
                  type="text"
                  name="lastName" />
              </div>

              <div className="first-time__buttons">
                <SubmitButton
                  key="first-time__submit-button"
                  text="Get Started"
                  icon={faCheck}
                  submitted={isSubmitting} />
              </div>
            </Form>;
          }} />
  </div>;
};
