import React, { useEffect, useState, useReducer } from 'react';

import { DateTime } from 'luxon';
import { API } from 'aws-amplify';
import { faLevelUpAlt, faRoute, faBug, faCalculator, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import ResizeDetector from 'react-resize-detector';
import { Link } from 'react-router-dom';

import Error from '../../components/error';
import Summary from '../../components/view-report/summary';
import Sections from '../../components/view-report/sections';
import Loading from '../../components/loading';
import QuickFunctions from '../../components/quick-functions';

import './view-report.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ReportError = ({ error }) => {
  const missing = <Error title="We got a bit lost" icon={faRoute} content={<>Opps! We can't seem to find that, you could try going <a href="javascript:history.go(-1)">back</a> or if you're certain it should be there, contact support.</>} />; // eslint-disable-line jsx-a11y/anchor-is-valid, no-script-url
  const Broken = ({ errorCode }) => <Error
    icon={faBug}
    title="Sorry!"
    content={<>So sorry, we're not sure what's happened there, try going back to the <Link to="/">main screen</Link>, if you continue to have issues then please let us know, providing error code: <b>{errorCode}</b></>}
  />;

  const errorMappings = {
    '404': missing,
    '403': missing,
    '500': <Broken errorCode="albatross" />
  };

  if (error.response && errorMappings[error.response.status.toString()]) {
    return errorMappings[error.response.status.toString()];
  }

  return <Broken errorCode="kangaroo" />;
};

const WaitForProcessing = ({ dispatch, secondsSinceCreated, increment }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch({ event: 'refresh' });
    }, 2500);
    return () => clearTimeout(timer);
  }, [increment]); // eslint-disable-line react-hooks/exhaustive-deps

  return <div className="report-processing">
    <div className="report-processing__spinner__container">
      <div className="report-processing__spinner"><FontAwesomeIcon icon={faCircleNotch} /></div>
    </div>
    <div className="report-processing__icon"><FontAwesomeIcon icon={faCalculator} /></div>
    {increment > 2 
      ? <div>Sorry we're still processing the report, it's taking a little longer than usual...</div>
      : <div>We're just processing the report now, please stand by...</div>}      
  </div>;
};

const ReportDetail = ({ reference }) => {
  const [error, updateError] = useState();
  const [data, updateData] = useState();
  const [loading, updateLoading] = useState(true);
  const [state, dispatch] = useReducer((state, { event }) => {
    if (event === 'refresh') {
      if (state.increment > 4) {
        return state;
      }

      return { increment: state.increment + 1 };
    }

  }, { increment: 0 });

  function useFetch(url, increment) {
    useEffect(() => {
      const getData = async () => {
        try {
          const resp = await API.get('measureitnow', url);

          updateData(resp);
          updateLoading(false);
        } catch (e) {
          updateError(e);
          updateLoading(false);
        }
      };
      getData();
    }, [url, increment]);

    return data;
  }

  const report = useFetch(`report/${reference}`, state.increment);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ReportError error={error} />;
  }

  const secondsSinceCreated = Math.abs(DateTime.fromISO(report.created).diffNow().as('seconds'));

  if (report.status === 'reported') {
    if (secondsSinceCreated > 60) {
      return <Error
        icon={faBug}
        title="Sorry!"
        content={<>So sorry, something seems to have gone wrong processing your report, we should already know something's not quite right, but contact support providing the report reference and error code: <b>swallowtail</b></>}
      />;
    }

    return <WaitForProcessing dispatch={dispatch} secondsSinceCreated={secondsSinceCreated} increment={state.increment} />;
  }

  return <ResizeDetector handleWidth refreshMode="throttle" refreshRate={250}>
    <ViewReportContainer report={report} />
  </ResizeDetector>;
};

const ViewReportContainer = ({ width, ...props }) => <div className={width < 1000 ? 'view-report__mobile-view' : ''}>
  <Summary width={width} {...props} />
  <Sections width={width} {...props} />
</div>;

export default ({ match }) => {
  const { reference } = match.params;

  return <div>
    <h2>Viewing Report {reference}</h2>

    <QuickFunctions buttons={[
      { icon: faLevelUpAlt, text: 'Back to Reports', link: '/reports' },
    ]} />

    <ReportDetail reference={reference} />
  </div>;
};
