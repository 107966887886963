import React from 'react';

import { Panel } from '../../responsive-panels';
import List from '../../list';

import './reviews.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCalendar, faUser, faFileAlt } from '@fortawesome/free-solid-svg-icons';

const PanelContent = ({ reviewSummary }) => {
  if (!reviewSummary.items.length) {
    return <>
      <div className="review-panel__queue-empty__icon">
        <FontAwesomeIcon icon={faCheckCircle} />
      </div>
      <div className="review-panel__queue-empty__text">
        No Reports to review
      </div>
    </>;
  }

  return <List icon={faFileAlt} items={reviewSummary.items.map(({
    reference,
    created,
    author,
  }) => ({
    title: reference,
    content: <>
      <div className="reviews-panel__report-list__item__title">{reference}</div>
      <div className="reviews-panel__report-list__item__details">
        <div className="reviews-panel__report-list__item__details__created">
          <FontAwesomeIcon icon={faCalendar} />&nbsp;{created} (34 minutes ago)
        </div>
        <div className="reviews-panel__report-list__item__details__author">
          <FontAwesomeIcon icon={faUser} />&nbsp;{author.firstName} {author.lastName}
        </div>
      </div>
    </>
  }))} />;
};

export default ({ userContext: { userRole } }) => {
  const reviewSummary = {
    items: [
    ],
  };

  return <Panel
    id="home_reviews_panel"
    title="Reviews"
    userRole={userRole}
    data={reviewSummary}
    content={(reviewSummary) => <PanelContent reviewSummary={reviewSummary} />}
    permittedRoles={['system-admin', 'owner', 'admin', 'team-lead']} />;
};
