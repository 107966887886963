import React from 'react';

import FormField from '../../../components/form/standard-form-field';
import Button from '../../../components/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

const ErrorWrapper = ({ error, children }) => error
  ? <div className="value-list__error__container">
    {children}
    <div className="value-list__error__message">
      <FontAwesomeIcon icon={faExclamationTriangle} /> {error}
    </div>
  </div>
  : <>{children}</>;

export default ({
  title,
  dataType,
  name,
  report = {},
  errors = {},
  touched = {},
  allowNewRows = false,
  submitted,
  dispatch,
}) => {
  const matchingFields = Object.keys(report).filter(key => key.startsWith(name));
  const values = matchingFields.reduce((max, key) => {
    const indexRegex = /[^[]+\[([0-9]+)]/;
    const indexMatches = indexRegex.exec(key);
    if (!indexMatches) {
      return max;
    }
    const parsedMatch = parseInt(indexMatches[1], 10);

    return parsedMatch > max ? parsedMatch : max;
  }, 0);
  const rows = Array(values + 1).fill(undefined).map((row, i) => i);

  return <div className="edit-report__value-list">
    {title ? <h3>{title}</h3> : <></>}
    <ErrorWrapper error={errors[name]}>
      <>
        {rows.map((i) => {
          const fieldName = `${name}[${i}]`;
          return <div key={`value-list__field__${i}`} className="edit-report__value-list__item">
            <div className="edit-report__value-list__item__label">
              {i + 1}.
            </div>
            <FormField
              errors={errors}
              touched={touched}
              submitted={submitted}
              type={dataType}
              name={fieldName}
              value={report[fieldName] || ''}
              onChange={(e) => dispatch({ name: fieldName, value: e.target.value })}
              onBlur={() => {
                dispatch({ event: 'touched', name });
                dispatch({ event: 'touched', name: fieldName });
              }}
              />
          </div>;
        })}
        {allowNewRows
          ? <div className="edit-report__value-list__buttons">
              <div className="edit-report__value-list__item__label">
                &nbsp;
              </div>
              <Button icon={faPlusCircle} onClick={(e) => {
                dispatch({ name: `${name}[${values + 1}]`, value: '' });
              }}>
                Add New Row
              </Button>
            </div>
          : <></>}
      </>
    </ErrorWrapper>
  </div>;
};
