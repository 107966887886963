import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft, faDotCircle, faTimes, faCheck, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

import Button from '../../../components/button';
import renderSection from './';
import { toKebabCase } from '../../../utilities/string';

const getErrorsByPanel = (errors, touched, submitted, panels) => {
  if (typeof panels === 'string') {
    panels = [panels];
  };

  const entries = Object.entries(errors);

  const nameRegex = /([^.]+)\./;

  return entries
    .reduce((grouped, [name, value]) => {
      const matches = nameRegex.exec(name);
      const root = name.indexOf('.') > -1 ? name.substring(0, name.lastIndexOf('.')) : name;

      if (!matches || (!touched[root] && !touched[name] && !submitted)) {
        return grouped;
      }

      const panel = matches[1];

      if (panels.includes(panel)) {
        if (!grouped[panel]) {
          grouped[panel] = {};
        }
        grouped[panel][name.replace(matches[0], '')] = value;
      }

      return grouped;
    }, {});
}

const getErrorsForPanel = (errors, touched, submitted, panel) => {
  const all = getErrorsByPanel(errors, touched, submitted, [panel]);

  return all[panel];
};

const getTabStyle = (completed, selected, errored) => {
  const selectedStyle = selected ? 'tab__selected ' : '';

  if (errored) {
    return { icon: faExclamationTriangle, cssStyle: `${selectedStyle}tab__errored` };
  }

  if (selected) {
    return { icon: faDotCircle, cssStyle: selectedStyle };
  }

  if (completed) {
    return { icon: faCheck, cssStyle: selectedStyle };
  }

  return { icon: faCircle };
};

const TabSelector = ({ panels, selectedTab, changeTab, completedPanels, errors, panelError, submitted }) => <ul className="edit-report__panels__tabs">
  {panels.map(({ title, id = toKebabCase(title) }, i) => {
    const isSelected = selectedTab === id;
    const { icon, cssStyle } = getTabStyle(completedPanels[id], isSelected, errors[id]);

    return <li
      onClick={changeTab.bind(undefined, id)}
      className={`edit-report__panels__tabs__tab${cssStyle ? ` ${cssStyle}` : ''}${i === panels.length - 1 ? ' edit-report__panels__tabs__last-tab' : ''}`}
      key={id}>
      <div className="edit-report__panels__tabs__tab__status">
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className="edit-report__panels__tabs__tab__text">{title}</div>
      <div className="edit-report__panels__tabs__tab__icon"><FontAwesomeIcon icon={faChevronRight} /></div>
    </li>;
    })
  }
  {panelError && submitted ? <li className="edit-report__panels__error">
    <div className="edit-report__panels__error__icon"><FontAwesomeIcon icon={faExclamationTriangle} /></div>
    <div className="edit-report__panels__error__text">{panelError}</div>
  </li> : <></>}
</ul>;

const SingleTabHeader = ({ panels, selectedTab, changeTab, completedPanels, errors }) => {
  const [currentTab] = panels.filter(({ title, id = toKebabCase(title) }) => id === selectedTab);
  const { icon, cssStyle } = getTabStyle(completedPanels[selectedTab], true, errors[selectedTab]);

  return <div className={`edit-report__panels__tabs__tab${cssStyle ? ` ${cssStyle}` : ''}`}>
    <div className="edit-report__panels__tabs__tab__status">
      <FontAwesomeIcon icon={icon} />
    </div>
    <div className="edit-report__panels__tabs__tab__text">{currentTab.title}</div>
    <div className="edit-report__panels__tabs__tab__icon" onClick={changeTab.bind(undefined, '')}><FontAwesomeIcon icon={faTimes} /></div>
  </div>;
};

const TabContent = ({
  mobileMode,
  completedPanels,
  panels,
  dispatch,
  selectedTab,
  errors,
  touched,
  report = {},
  events: {
    panelCompleted,
    onUpdate,
    onChange,
  },
  validateForm
}) => {
  const completedPanelEntries = Object.entries(completedPanels);
  const allCompleted = panels.every(({ name }) => completedPanels[name]);
  const completedPanelNames = completedPanelEntries.filter(([, completed]) => completed).map(([name]) => name);
  const selectedIsCompleted = completedPanelNames.includes(selectedTab);
  let bigText = 'Complete at least one report section';

  const prependIdToName = (name, id) => {
    if (typeof name === 'string') {
      return [`${id}.${name}`];
    }

    return name.map(item => `${id}.${item}`);
  };

  if (allCompleted) {
    bigText = 'All Sections complete, click Submit below.';
  } else if(completedPanelNames.length) {
    bigText = 'Complete more Sections, or click Submit below';
  }

  return <>
    {selectedTab || mobileMode
      ? <></>
      : <div className="edit-report__panels__content">
          <div className="edit-report__panels__not-selected">
            <div className="edit-report__panels__not-selected__icon">
              <FontAwesomeIcon icon={allCompleted ? faCheck : faChevronLeft} />
            </div>
            <div className="edit-report__panels__not-selected__text">
              {bigText}
            </div>
          </div>
        </div>}
    {panels
      .filter(({ title, id = toKebabCase(title) }) => id === selectedTab)
      .map(({ title, id = toKebabCase(title), sections, showButton = true }) => {
        return <div className="edit-report__panels__content" key={`tab__${id}`}>
          {mobileMode || !title ? <></> : <h2>{title}</h2>}

          <div className="edit-report__panels__content__sections">
            {sections.map((section, index) => renderSection(section, {
              errors: errors[id],
              touched: touched,
              report: report[id],
              validateForm,
              events: { panelCompleted },
              dispatch: ({ name, ifValid, ...rest }) => {
                dispatch({
                  event: 'touched',
                  name: id,
                  ...rest,
                });

                dispatch({
                  name: prependIdToName(name, id),
                  ifValid: ifValid ? `${id}.${ifValid}` : undefined,
                  ...rest,
                });
              },
              onChange: ({ name, ...rest }) => onChange({
                name: prependIdToName(name, id),
                ...rest,
              }),
              index: index + 1,
            }))}
          </div>

          {showButton
            ? <div className="edit-report__panels__content__buttons">
              <Button icon={faCheck} className="submit" onClick={panelCompleted}>{selectedIsCompleted ? 'Update Section' : 'Complete Section'}</Button>
            </div>
            : <></>}
        </div>;
      })}
  </>;
}

export default ({
  id,
  mobileMode = false,
  dispatch,
  panels,
  onChange,
  report,
  errors,
  touched,
  submitted,
  validateForm,
  events: {
    onTabChange = () => {},
    onUpdate = () => {},
    onCompletion = () => {},
  } = {},
  setFocus,
}) => {
  const [selectedTab, setTab] = useState('');
  const panelNames = panels.map(({ name }) => name);
  const completedPanels = Object.fromEntries(Object.entries(report)
    .filter(([name]) => panelNames.includes(name))
    .map(([name, { completed = false } = {}]) => [name, completed]));

  const changeTab = (tab) => {
    if (selectedTab === tab) {
      return;
    }

    setTab(tab);
    onTabChange(tab);

    setFocus(tab ? id : '');
  };

  const panelCompleted = async () => {
    const errors = await validateForm();

    const relevantErrors = getErrorsForPanel(errors, touched, true, selectedTab);

    dispatch({ event: 'touched', name: selectedTab });
    console.log(relevantErrors);

    if (!relevantErrors || !Object.keys(relevantErrors).length) {
      console.log(`${selectedTab} completed`);
      completedPanels[selectedTab] = true;
      // setCompletion(completedPanels);
      onCompletion(selectedTab);
      changeTab('');
    }
  };
  const errorsByPanel = getErrorsByPanel(errors, touched, submitted, panelNames);

  return <div className="edit-report__panels">
    {mobileMode && selectedTab
      ? <SingleTabHeader panels={panels} completedPanels={completedPanels} selectedTab={selectedTab} changeTab={changeTab} errors={errorsByPanel} />
      : <TabSelector panels={panels} completedPanels={completedPanels} selectedTab={selectedTab} changeTab={changeTab} errors={errorsByPanel} touched={touched} submitted={submitted} panelError={errors[id]} />}
    
    <TabContent
      panels={panels}
      errors={errorsByPanel}
      touched={touched}
      completedPanels={completedPanels}
      selectedTab={selectedTab}
      report={report}
      mobileMode={mobileMode}
      validateForm={validateForm}
      dispatch={dispatch}
      events={{ onUpdate, panelCompleted, onChange }} />
  </div>;
};
