import React from 'react';

import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListUl, faCalendar, faUser, faFileAlt, faWindowClose } from '@fortawesome/free-solid-svg-icons';

import Button from '../../button';
import List from '../../list';
import { Panel } from '../../responsive-panels';

import './reports.css';

const PanelContent = ({ reportSummary }) => {
  if (!reportSummary.items.length) {
    return <>
      <div className="reports-panel__queue-empty__icon">
        <FontAwesomeIcon icon={faWindowClose} />
      </div>
      <div className="reports-panel__queue-empty__text">
        No Reports created recently, <Link to="/report/new">Create One</Link> now?
      </div>
    </>;
  }

  return <List icon={faFileAlt} items={reportSummary.items.map(({
    reference,
    created,
    userFullName,
  }) => {
    const createdDate = DateTime.fromISO(created);
    return {
      title: reference,
      link: `/report/${reference}`,
      key: `report-item__${reference}`,
      content: <div className="reports-panel__report-list__item__details">
        <div className="reports-panel__report-list__item__details__created">
          <FontAwesomeIcon icon={faCalendar} />&nbsp;{createdDate.toLocaleString(DateTime.DATETIME_SHORT)} ({createdDate.toRelative()})
        </div>
        <div className="reports-panel__report-list__item__details__author">
          <FontAwesomeIcon icon={faUser} />&nbsp;{userFullName}
        </div>
      </div>
    };
  })} />
};

export default ({ userContext: { teamId, userRole } }) => <Panel
  id="home_reports_panel"
  title="Recent Reports"
  apiEndpoint={['admin', 'owner', 'system-admin'].includes(userRole) ? `team/${teamId}/reports?limit=5` : 'me/reports?limit=5'}
  content={(reportSummary) => <PanelContent reportSummary={reportSummary} />}
  buttons={[
    <Button icon={faListUl} text="View Reports" link="/reports" key="panels__report__view-button" />,
  ]} />;
