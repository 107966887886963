import React, { useEffect, useState } from 'react';
import { API } from "aws-amplify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug, faRedoAlt } from '@fortawesome/free-solid-svg-icons';

import Loading from '../loading';
import Button from '../button';

import './responsive-panels.css';

const classifyError = (error) => {
  if (error.message === 'Network Error') {
    return { type: 'network', code: 'anteater', canRetry: true };
  }

  if (error.response && error.response.status === 403) {
    return { type: 'unauthorized', code: 'ketchup', canRetry: false };
  }

  if (error.response && error.response.status === 500) {
    return { type: 'internal-server', code: 'albatross', canRetry: true };
  }

  return { type: 'unknown', code: 'kangaroo', canRetry: true };
};

const PanelError = ({ error: { type, code, canRetry = false } }) => {
  return <div className="panels__panel__error">
    <div className="panels__panel__error__icon">
      <FontAwesomeIcon icon={faBug} />
    </div>
    <div className="panels__panel__error__text">
      Uh-oh! It appears something went wrong. Please {canRetry ? 'try again by clicking below.  Or if the problem keeps happening ' : ''}report it with the error code "{code}".
    </div>
  </div>
};

export const Panel = ({
  title,
  id = `panel_${title.toLowerCase().replace(/ /g, '_')}`,
  icon,
  data,
  content,
  apiEndpoint,
  buttons = [],
  userRole,
  permittedRoles = [],
}) => {
  if (permittedRoles.length && !permittedRoles.includes(userRole)) {
    return <></>;
  }

  const [panelData, updateData] = useState(data); // eslint-disable-line react-hooks/rules-of-hooks
  const [loaded, updateLoaded] = useState(data ? true : false); // eslint-disable-line react-hooks/rules-of-hooks
  const [error, updateError] = useState(false); // eslint-disable-line react-hooks/rules-of-hooks
  const [increment, updateIncrement] = useState(0); // eslint-disable-line react-hooks/rules-of-hooks

  if (apiEndpoint) {
    useEffect(() => { // eslint-disable-line react-hooks/rules-of-hooks
      const getData = async () => {
        try {
          const resp = await API.get('api', apiEndpoint);
          updateData(resp);
          updateLoaded(true);
        } catch (e) {
          const errorClassification = classifyError(e);
          updateError(errorClassification);
        }
      };
  
      getData();
    }, [apiEndpoint, increment]);
  }

  if (error) {
    return <div className="panels__panel" key={id}>
      <h3 className="panels__panel__title">{title}</h3>
      <PanelError error={error} />
      <div className="panels__panel__buttons">
        {error.canRetry ? <Button icon={faRedoAlt} text="Try Again" onClick={() => updateIncrement(increment + 1)} /> : <></>}
      </div>
    </div>;
  }

  return <div className="panels__panel" key={id}>
    <h3 className="panels__panel__title">{title}</h3>
    {loaded ? content(panelData) : <Loading />}

    <div className="panels__panel__buttons">
      {(buttons.forEach ? buttons : buttons(panelData)).map(button => button)}
    </div>
  </div>;
};

export const Panels = ({ children }) => <div className="panels">
  {children}
</div>;
