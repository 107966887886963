import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faTimes, faExclamationTriangle, faCheck } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useLocation } from 'react-router-dom';

import AC from './ac';
import Foundations from './foundations';
import VoltageDrop from './voltage-drop';
import { toKebabCase } from '../../../utilities/string';

import './sections.css';

const Tabs = ({ tabs, mobileMode = false, defaultTab, onChange, bindSelectTab, report }) => {
  const location = useLocation();

  const [clickedTab, setClickedTab] = useState(location.hash ? location.hash.substring(1) : undefined);
  const [selectedTab, setSelectedTab] = useState(defaultTab);

  if (bindSelectTab) {
    bindSelectTab((tab) => {
      setClickedTab(tab);
      setSelectedTab(tab);
    });
  }

  const selectTab = (id) => {
    if (!mobileMode && id === selectedTab) {
      return;
    }

    if (id) {
      setSelectedTab(id);
    }
    setClickedTab(id);
    
    if (onChange) {
      onChange(id);
    }
  };

  if (!tabs.length) {
    return <></>;
  }

  const Tab = ({ id, selectTab, title, reportSection = {}, isSelected = false, showStatus = true }) => {
    const discrepency = typeof reportSection.pass !== 'undefined' && !reportSection.pass;
    const statusIcon = discrepency ? <FontAwesomeIcon icon={faExclamationTriangle} /> : <FontAwesomeIcon icon={faCheck} />;
    return <li
      onClick={selectTab.bind(undefined, id)}
      className={`view-report__sections__tabs__tab ${isSelected ? ' tab__selected' : ''}`}
      key={id}>
      <div className="view-report__sections__tabs__tab__status">{showStatus ? statusIcon : <></>}</div>
      
      <div className="view-report__sections__tabs__tab__text">{title}</div>
      <div className="view-report__sections__tabs__tab__icon"><FontAwesomeIcon icon={faChevronRight} /></div>
    </li>;
  };

  if (mobileMode) {
    if (!clickedTab && tabs.length > 1) {
      return <ul className="view-report__sections__tabs">
        {tabs.map(({ title, showStatus, id = toKebabCase(title) }) => <Tab id={id} selectTab={selectTab} title={title} showStatus={showStatus} reportSection={report[id]} />)}
      </ul>;
    }

    const [selectedTab] = tabs.length === 1 ? tabs : tabs.filter(({ title, id = toKebabCase(title) }) => id === clickedTab);

    return <div className="view-report__sections__tabs__tab tab__selected">
      <div className="view-report__sections__tabs__tab__text">{selectedTab.title}</div>
      {tabs.length > 1
        ? <div className="view-report__sections__tabs__tab__icon" onClick={selectTab.bind(undefined, undefined)}><FontAwesomeIcon icon={faTimes} /></div>
        : <></>}
    </div>;
  }

  return <ul className="view-report__sections__tabs">
    {tabs.map(({ title, showStatus, id = toKebabCase(title) }) => <Tab
      id={id}
      isSelected={selectedTab === id}
      showStatus={showStatus}
      selectTab={selectTab}
      title={title}
      reportSection={report[id]} />)}
  </ul>;
};

export default ({ report, width }) => {
  const history = useHistory();
  const location = useLocation();

  const [tabSet, setTabSet] = useState(location.hash ? true : false);
  const [tab, setTab] = useState(location.hash ? location.hash.substring(1) : 'foundations');
  const tabs = [
    {
      title: 'Foundations',
      content: (props) => <Foundations {...props} />,
    },
    {
      title: 'Voltage Drop',
      content: (props) => <VoltageDrop {...props} />,
      showStatus: false,
      reportProperty: 'voltage-drop',
    },
    {
      title: 'AC',
      content: (props) => <AC {...props} />,
    },
  ].map(({ title, id = toKebabCase(title), ...rest }) => ({
    ...rest,
    title,
    id,
  }));
  
  const tabIds = tabs.map(({ id, reportProperty }) => reportProperty  || id);
  const presentReportSections = Object.entries(report)
    .reduce((all, [key, values]) => {
      if (tabIds.includes(key) && Object.keys(values).length ? true : false) {
        all.push(key);
      }

      return all;
    }, []);
  const visibleTabs = tabs.filter(({ id, reportProperty }) => presentReportSections.includes(reportProperty || id));

  if (!visibleTabs.length) {
    return <></>;
  }

  const tabChanged = (newTab) => {
    if (newTab === tab && tabSet) {
      return;
    }

    setTabSet(newTab ? true : false);
    setTab(newTab);

    const currentUrl = `${location.pathname}${location.hash}`;
    const targetUrl = `${location.pathname}${newTab ? `#${newTab}` : ``}`;

    if (currentUrl !== targetUrl) {
      history.push(targetUrl);
    }
  }
  
  let selectTab;
  const bindSelectTab = (newSelectTab) => selectTab = newSelectTab;

  React.useEffect(() => {
    history.listen((location) => {
      const newTab = location.hash ? location.hash.substring(1) : undefined;
      if (newTab === tab) {
        return;
      }

      setTabSet(true);
      setTab(newTab);
      
      if (selectTab) {
        selectTab(newTab);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [currentTab] = visibleTabs.length === 1 ? visibleTabs : visibleTabs.filter(({ title, id = toKebabCase(title) }) => id === tab);

  if (width < 1000) {
    return <>
      <div className="view-report__sections">
        <Tabs
          mobileMode={true}
          tabs={visibleTabs}
          onChange={tabChanged}
          bindSelectTab={bindSelectTab}
          report={report} />
      </div>
      <div className="view-report__sections__content">
        {currentTab && (tabSet || visibleTabs.length === 1) ? currentTab.content({ report, width }) : <></>}
      </div>
    </>;
  }

  return <div className="view-report__sections">
    <Tabs
      tabs={visibleTabs}
      defaultTab={tab}
      onChange={tabChanged}
      bindSelectTab={bindSelectTab}
      report={report} />

    <div className="view-report__sections__content">
      {currentTab ? currentTab.content({ report, width }) : <></>}
    </div>
  </div>;
};
