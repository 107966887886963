import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRuler } from '@fortawesome/free-solid-svg-icons';

import './logged-out.css';

export default () => <div className="logged-out">
  <h1><FontAwesomeIcon icon={faRuler} /> Measure It Now!</h1>
  <p>Welcome to the beta of Measure It Now! Please enter your email and password below.</p>
</div>;
