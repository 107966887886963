import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import './status-message.css';

export default ({ text, state = 'ok' }) => {
  if (!text) {
    return <></>
  }

  const icon = state === 'error' ? faExclamationTriangle : faInfoCircle;

  return <div className={`form__status-message ${state}`}>
    <div className="form__status-message__icon"><FontAwesomeIcon icon={icon} /></div>
    <div className="form__status-message__text">
      {text}
    </div>
  </div>;
}
