import React from 'react';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';

import Button from '../../button';
import { Panel } from '../../responsive-panels';
import PlanSummary from '../../plan-summary';

export default ({ userContext: { teamId } }) => {
  return <Panel
    title="Your Plan"
    content={(planDetails) => <PlanSummary planSummary={planDetails} />}
    apiEndpoint={`team/${teamId}/usage`}
    buttons={[
      <Button icon={faChartBar} text="Detailed Usage" link="/team/usage" />,
    ]} />;
};
