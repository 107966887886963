import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import './form-field.css';

const FormErrorMessage = ({ errors, touched, name, submitted }) => {
  if (!errors[name] || (!touched[name] && !submitted)) {
    return <></>;
  }

  return <div className="form-control-validation-message">
    <FontAwesomeIcon icon={faExclamationTriangle} />&nbsp;
    {errors[name]}
  </div>;
};

const fields = {
  default: ({ label, value = "", fieldClassName, ...fieldProps }) => {
    const formField = <input
      {...fieldProps}
      value={value}
      className={`form-control${fieldClassName ? ` ${fieldClassName}` : ''}`} />;
    
    const fieldWithLabel = <label className="form__item__label">
      {label}<br/>
      {formField}
    </label>;

    return label ? fieldWithLabel : formField;
  },
  checkbox: ({ label, name, value, onChange }) => <label className="form__item-checkbox__label">
    <div className="form__item-checkbox__checkbox">
      <input type="checkbox" className="form__item-checkbox__checkbox__input" checked={value ? 'checked' : ''} name={name} onChange={(e) => {
        onChange({
          target: {
            name: e.target.name,
            value: e.target.checked,
          },
        });
      }} />
    </div>
    <div className="form__item-checkbox__label">{label}</div>
  </label>,
};

export default ({
  errors,
  touched,
  submitted,
  name,
  type,
  fieldClassName,
  ...fieldProps
}) => {
  const formField = (fields[type] ? fields[type] : fields.default)({ fieldClassName, name, type, ...fieldProps });

  return <div className={'form__item__outer' + (errors[name] && (touched[name] || submitted) ? ' error' : '')}>
    <div className="form__item">
      {formField}
    </div>
    <FormErrorMessage errors={errors} touched={touched} submitted={submitted} name={name} />
  </div>;
};
