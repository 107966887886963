import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileMedical } from '@fortawesome/free-solid-svg-icons';

import EditReport from '../../components/edit-report';

export default () => <>
  <h2><FontAwesomeIcon icon={faFileMedical} /> Create New Report</h2>
  
  <EditReport />
</>;
