import React from 'react';

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './button.css';

const wrapWithLink = ({ button, link }) => {
  if (link) {
    return <Link to={link}>{button}</Link>;
  }

  return button;
};

const typeClass = (type) => {
  if (type === 'destructive') {
    return 'destructive';
  }
  
  if (type === 'submit') {
    return 'submit';
  }

  return '';
};

export default ({ icon, text, link, onClick, type, children, iconAnimated = false, className }) => {
  const iconElement = icon
    ? <div className={`button__icon${iconAnimated ? ' button__icon__animated' : ''}`}><FontAwesomeIcon icon={icon} /></div>
    : <></>;
  const onClickProps = onClick ? { onClick } : {};
  const classes = [
    'button',
    typeClass(type),
    className,
  ].filter(className => className);
  const button = <button type={type === 'submit' ? 'submit' : 'button'} className={classes.join(' ')} {...onClickProps}>
    {iconElement}<div className="button__text">{text || children}</div>
  </button>;

  return wrapWithLink({
    button,
    link,
  });
};
