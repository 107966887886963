import React from 'react';

import { Field, ErrorMessage } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import './form-field.css';

const FormErrorMessage = ({ text }) => <div className="form-control-validation-message">
  <FontAwesomeIcon icon={faExclamationTriangle} />&nbsp;
  {text}
</div>;

export default ({
  errors,
  touched,
  name,
  label,
  fieldClassName,
  ...fieldProps
}) => {
  const formField = <Field
    {...fieldProps}
    name={name}
    className={`form-control${fieldClassName ? ` ${fieldClassName}` : ''}`} />;

  const fieldWithLabel = <label className="form__item__label">
    {label}<br/>
    {formField}
  </label>;

  return <div className={'form__item__outer' + (errors[name] && touched[name] ? ' error' : '')}>
      <div className="form__item">
        {label ? fieldWithLabel : formField}
      </div>
      <ErrorMessage name={name}>
        {text => <FormErrorMessage text={text} />}
      </ErrorMessage>
    </div>;
};
