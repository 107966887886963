import React from 'react';

import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';

import Button from './';

export default ({ icon = faCheck, text, onClick, type, children, submitted }) => {
  const onClickHandler = () => {
    if (onClick) {
      onClick();
    }
  };

  return <Button icon={submitted ? faSpinner : icon} iconAnimated={submitted} type={type || 'submit'} onClick={onClickHandler}>{text || children}</Button>;
};
