import React, { useEffect, useState } from 'react';
import { API } from "aws-amplify";
import { Redirect } from 'react-router-dom';
import { faLevelUpAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useLocation } from "react-router-dom";
import { DateTime } from 'luxon';

import UserRoleIcon from '../../components/icons/user-role-icon';
import { kebabToSentenceCase } from '../../utilities/string';
import ResponsiveList from '../../components/responsive-list';
import QuickFunctions from '../../components/quick-functions';
import Loading from '../../components/loading';
import useUserContext from '../../user-context';

import './members.css';

const defaultQueryOptions = { userRole: 'all', sortBy: 'userRole_desc' };

const getParametersFromLocation = ({ search }) => search
  ? search.substring(1)
    .split('&')
    .reduce((all, item) => {
      const [key, value] = item.split('=');

      all[key] = value;

      return all;
    }, { })
  : { };

const buildDefaultOptions = (defaultOptions, location) => ({
  ...defaultOptions,
  ...getParametersFromLocation(location),
});

const OptionList = ({ options = [], name, selectedValue, onChange }) => <select name={name} onChange={onChange} value={selectedValue}>
  {options.map(({ value, text }) => <option value={value} key={`${name}__${value}`}>{text}</option>)}
</select>;

const updateHistory = (history, options) => {
  const currentUrl = `${document.location.pathname}${document.location.search ? document.location.search : ''}`;
  const targetUrl = options.length ? `${document.location.pathname}?${options.map(([key, value]) => `${key}=${value}`).join('&')}` : document.location.pathname;

  if (currentUrl !== targetUrl) {
    history.push(
      options.length ? `${document.location.pathname}?${options.map(([key, value]) => `${key}=${value}`).join('&')}` : document.location.pathname,
      options,
    );
  }
};

const Filters = ({ filterOptions, events = {} }) => {
  const filterValueUpdate = (key, value) => {
    const newFilterOptions = {
      ...filterOptions,
    };
    newFilterOptions[key] = value;
    
    if (events.filterUpdate) {
      events.filterUpdate(newFilterOptions);
    }
  };

  return <div className="filter">
    <h3>Filter/Sort Users</h3>

    <label className="filter__item">
      User Role: 
      <OptionList name="userRole" options={[
        { value: 'all', text: 'All User Roles' },
        { value: 'inspector', text: 'Inspector' },
        { value: 'team-lead', text: 'Team Lead' },
        { value: 'admin', text: 'Admin' },
        { value: 'owner', text: 'Owner' },
        { value: 'deactivated', text: 'Deactivated' },
      ]} selectedValue={filterOptions.userRole} onChange={(e) => {
        filterValueUpdate('userRole', e.target.value);
      }} />
    </label>

    <label className="filter__item">
      Sort by:
      <OptionList name="sortBy" options={[
        { value: 'userRole_desc', text: 'User Role' },
        { value: 'userName_asc', text: 'User Name (asc)' },
        { value: 'userName_desc', text: 'User Name (desc)' },
        { value: 'lastLogin_desc', text: 'Most Recent Login' },
        { value: 'lastLogin_asc', text: 'Least Recent Login' },
      ]} selectedValue={filterOptions.sortBy} onChange={(e) => {
        filterValueUpdate('sortBy', e.target.value);
      }} />
    </label>
  </div>;
};

const getChangedParameters = (defaults, queryFilter) => (queryFilter.forEach ? queryFilter : Object.entries(queryFilter))
  .reduce((result, [key, value]) => {
    if (defaults[key] === value) {
      return result;
    }

    result.push([key, value]);

    return result;
  }, []);

const buildQuerystring = (increment, updatedOptions) => {
  if (increment) {
    updatedOptions.push(['increment', increment]);
  }

  if (!updatedOptions.length) {
    return '';
  }

  return `?${updatedOptions.map(([key, value]) => `${key}=${value}`).join('&')}`;
};

const TeamMemberList = ({ userContext: { teamId } }) => {
  const [data, updateData] = useState();
  let onLoadCompleteFunction;
  let showInlineLoadingFunction;

  function useFetch(url) {
    useEffect(() => {
      const getData = async () => {
        const resp = await API.get('api', url);
        updateData(resp);
        setLoading(false);

        if (onLoadCompleteFunction) {
          onLoadCompleteFunction();
        }
      };
      getData();
    }, [url]);

    return data;
  }

  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [increment, setIncrement] = useState(0);
  const [queryFilter, setQueryFilter] = useState(buildDefaultOptions(defaultQueryOptions, location));
  const parameters = getChangedParameters(defaultQueryOptions, queryFilter);

  history.listen((location) => {
    console.log('history listen');
    const newParametersFromLocation = {
      ...defaultQueryOptions,
      ...getParametersFromLocation(location)
    };
    const changedParameters = Object.entries(newParametersFromLocation).filter(([key, value]) => queryFilter[key] !== value);

    if (changedParameters.length) {
      setQueryFilter(newParametersFromLocation);

      if (showInlineLoadingFunction) {
        showInlineLoadingFunction();
      }
    }
  });

  const users = useFetch(`team/${teamId}/members${buildQuerystring(increment, parameters)}`);

  const refresh = () => {
    setIncrement(increment + 1);
  };

  const filterUpdate = (newFilter) => {
    showInlineLoadingFunction();
    const allOptions = {
      ...queryFilter,
      ...newFilter,
    };

    setQueryFilter(allOptions);
    updateHistory(history, getChangedParameters(defaultQueryOptions, allOptions));
  }

  const onLoadComplete = (newOnLoadCompleteFunction) => {
    onLoadCompleteFunction = newOnLoadCompleteFunction;
  };

  const onLoadStart = (newOnLoadStartFunction) => {
    showInlineLoadingFunction = newOnLoadStartFunction;
  }

  if (loading || !users) {
    return <div className="loading-wrapper">
      <Loading height={100} />
    </div>;
  }

  const columns = [
    { header: 'Name', isHeader: true, width: 220, value: ({ firstName, lastName }) => `${firstName} ${lastName}` },
    { header: 'Role', width: 130, value: ({ userRole }) => kebabToSentenceCase(userRole) },
    { header: 'Email', width: 330, value: 'email' },
    {
      header: 'Last Login',
      width: 230,
      required: false,
      value: ({ lastLogin }) => {
        if (!lastLogin) {
          return 'Last Login Unknown';
        }

        return `Last Login ${DateTime.fromISO(lastLogin).toRelative()} (${DateTime.fromISO(lastLogin).toLocaleString(DateTime.DATETIME_SHORT)})`;
      },
    },
  ];

  return <>
    <Filters events={{ filterUpdate }} filterOptions={queryFilter} />

    <ResponsiveList
      items={users.teamMembers.map(user => ({
        key: user.username,
        link: `/team/member/${user.username}`,
        icon: <UserRoleIcon role={user.userRole} />,
        ...user,
      }))}
      columns={columns}
      itemName="team member"
      events={{ refresh, onLoadStart, onLoadComplete }}
    />
  </>;
};

export default () => {
  const userContext = useUserContext();

  if(!userContext || !userContext.teamId) {
    return <></>;
  }

  if(!['admin', 'owner', 'system-admin'].includes(userContext.userRole)) {
    return <Redirect to="/403" />;
  }

  return <>
    <h2>Manage your Team - Members</h2>

    <QuickFunctions buttons={[
      { icon: faLevelUpAlt, text: 'Back to Team Management', link: '/team' },
      { icon: faUserPlus, text: 'Invite Team Member', link: '/team/invite' },
    ]} />

    <TeamMemberList userContext={userContext} />
  </>;
};
