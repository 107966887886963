import React from 'react';

import { Redirect } from 'react-router-dom';

import { Panels } from '../../components/responsive-panels';
import TeamMembersPanel from '../../components/team-management/panels/team-members';
import PlanPanel from '../../components/team-management/panels/plan';
import InvitationsPanel from '../../components/team-management/panels/invitations';

import useUserContext from '../../user-context';

export default () => {
  const userContext = useUserContext();

  if(!userContext || !userContext.teamId) {
    return <></>;
  }

  if(!['admin', 'owner', 'system-admin'].includes(userContext.userRole)) {
    return <Redirect to="/403" />;
  }

  return <>
    <h2>Manage your Team</h2>
    
    <Panels>
      <PlanPanel userContext={userContext} />
      <TeamMembersPanel userContext={userContext} />
      <InvitationsPanel userContext={userContext} />
    </Panels>
  </>;
};
