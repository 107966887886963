import React from 'react';

import { faUserSlash, faUserEdit, faKey, faEnvelope, faUserCheck, faTrash, faLevelUpAlt, faCrown } from '@fortawesome/free-solid-svg-icons';

import QuickFunctions from '../quick-functions';

const getButtons = ({ userContext, userInfo, openDialog }) => {
  if (userInfo.username === userContext.username) {
    return [];
  }

  if (userInfo.userRole === 'deactivated') {
    return [
      { icon: faLevelUpAlt, text: 'Back to Team Members', link: `/team/members?userRole=deactivated` },
      { icon: faUserCheck, text: 'Reactivate User', type: 'submit', onClick: () => openDialog('reactivate') }
    ];
  }
  
  if (userInfo.userRole === 'invited') {
    return [
      { icon: faLevelUpAlt, text: 'Back to Invitations', link: `/team/invitations` },
      { icon: faEnvelope, text: 'Resend Invitation', onClick: () => openDialog('resendInvitation') },
      { icon: faTrash, text: 'Delete User', type: 'destructive', onClick: () => openDialog('delete') },
    ];
  }
  
  if (userInfo.userRole === 'owner') {
    return [
      { icon: faLevelUpAlt, text: 'Back to Team Members', link: `/team/members` },
      { icon: faKey, text: 'Reset Password', onClick: () => openDialog('resetPassword') },
  ];
  }

  return [
    { icon: faLevelUpAlt, text: 'Back to Team Members', link: `/team/members` },
    { icon: faUserEdit, text: 'Edit User', link: `/team/member/${userInfo.username}/edit` },
    { icon: faKey, text: 'Reset Password', onClick: () => openDialog('resetPassword') },
    ...(userInfo.userRole === 'admin' ? [{ icon: faCrown, text: 'Make Owner', onClick: () => openDialog('makeOwner') }] : []),
    { icon: faUserSlash, text: 'Deactivate User', type: 'destructive', onClick: () => openDialog('deactivate') },
  ];
};

export default (props) => <QuickFunctions buttons={getButtons(props)} />;
