import React, { useState } from 'react';

import { Redirect } from 'react-router-dom';
import { API } from "aws-amplify";
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom';

import useUserContext from '../../user-context';
import FormField from '../../components/form/form-field';
import StatusMessage from '../../components/form/status-message';
import QuickFunctions from '../../components/quick-functions';
import SubmitButton from '../../components/button/submit-button';
import ToastBar from '../../components/toast-bar';

import './invite-user.css';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const validateEmailAddress = (email) => /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i.exec(email); // eslint-disable-line

export default () => {
  const userContext = useUserContext();
  const [toastOpen, setToastOpen] = useState(false);

  if(!userContext || !userContext.teamId) {
    return <></>;
  }

  if(!['admin', 'owner', 'system-admin'].includes(userContext.userRole)) {
    return <Redirect to="/403" />;
  }

  const { teamId } = userContext; 

  return <>
    <ToastBar open={toastOpen} events={{ onClose: () => setToastOpen(false) }}>
      <FontAwesomeIcon icon={faUserPlus} />&nbsp;User invited (<Link to="/team/invitations">View Invitations</Link>)
    </ToastBar>
    <h2>Invite User</h2>
    
    <QuickFunctions buttons={[
      { type: 'back' },
    ]} />
    <div className="invite-user__wrapper">
      <div className="invite-user__description">
        <div className="invite-user__description__icon">
          <FontAwesomeIcon icon={faUserPlus} />
        </div>
        <p>
          Enter the email address of the user you wish to invite, and press invite user.  They will receive an email with a temporary password which will be valid for 7 days.
        </p>
        <p>
          If the password is not used within 7 days, then a new one can be sent to the user by viewing the invitation from team management.
        </p>
      </div>
      <Formik
        initialValues={{
          invite_emails: '',
        }}
        validate={values => {
          let errors = {};

          if (!validateEmailAddress(values.invite_emails)) {
            errors.invite_emails = 'Invalid email address';
          }

          return errors;
        }}
        onSubmit={async (values,
          {
            setSubmitting,
            setValues,
            setStatus,
            setErrors
          }) => {
            setSubmitting(true);

            try {
              await API.post('api', `team/${teamId}/invite`, {
                body: {
                  emails: [values.invite_emails],
                },
              });

              setValues({ invite_emails: '' });
              setToastOpen(true);
              setStatus(undefined);
            } catch (e) {
              setSubmitting(false);

              if (e.message === 'Network Error') {
                setStatus({ text: 'Could not contact Server, please try again later', state: 'error' });
                return;
              }

              if (e.response.status === 400) {
                if (!e.response.data || !e.response.data.message) {
                  setStatus({ text: e.message });

                  return;
                }

                if (e.response.data.message === 'User already exists') {
                  setErrors({ invite_emails: 'User already exists with email address' });
                }
              }
            }
        }}
        render={({ values, errors, status = {}, touched, submitForm, isSubmitting }) => {
          return <Form>

              <StatusMessage text={status.text} state={status.state} />
              <div className="invite-user__form">
                <FormField
                  errors={errors}
                  touched={touched}
                  type="text"
                  placeholder="Email Address"
                  fieldClassName="invite-user-dialog__email-address"
                  name="invite_emails" />
              </div>

              <div className="invite-user__buttons">
                <SubmitButton
                  key="invite-user__submit-button"
                  text="Invite User"
                  icon={faUserPlus}
                  submitted={isSubmitting} />
              </div>
            </Form>;
          }} />
        </div>
  </>;
};