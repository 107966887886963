import React from "react";
import Modal from "react-responsive-modal";

import Button from '../button';

import './modal.css';

class DialogModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { open: props.open || false };
  }

  static getDerivedStateFromProps(props, state) {
    state.open = props.open;

    return state;
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
    this.props.onClose();
  };

  render() {
    const { open } = this.state;
    const modalClasses = { modal: 'dialog-modal', closeButton: 'dialog-modal-close' };

    return (
        <Modal
          open={open}
          onClose={this.onCloseModal}
          classNames={modalClasses}>
          <h2 className="dialog-modal__title">{this.props.title}</h2>
          <div className="dialog-modal__content">
            {this.props.content}
          </div>
          <div className="dialog-modal__buttons">
            {this.props.buttons.map((button, i) => {
              if (button.$$typeof) {
                return button;
              }

              const key = `dialog-button-${i}`;
              const buttonClass = `button dialog-modal__button${button.primary ? ' primary' :  ''}`;
              const onClick = button.action === 'close' ? () => this.onCloseModal() : button.action;

              return <Button key={key} onClick={onClick} icon={button.icon} type={button.type} className={buttonClass}>{button.text}</Button>;
            })}
          </div>
        </Modal>
    );
  }
}

export default DialogModal;
