import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRuler } from '@fortawesome/free-solid-svg-icons';

import Menu from './menu';

import './top-bar.css';

export default ({ userContext }) => {
  const menu = userContext.userRole === 'invited' ? <></> : <Menu userContext={userContext} />;

  return <>
    <div className="top-bar">
      <nav>
        {menu}
        <h1><span className="top-bar__icon"><FontAwesomeIcon icon={faRuler} /></span> Measure It Now!</h1>
      </nav>
    </div>
  </>;
};
