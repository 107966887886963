import React from 'react';

import { Panels } from '../../components/responsive-panels';
import QuickFunctions from '../../components/home/quick-functions';
import TeamPanel from '../../components/home/panels/team';
import ReportsPanel from '../../components/home/panels/reports';
import ReviewsPanel from '../../components/home/panels/reviews';

import useUserContext from '../../user-context';

export default () => {
  const userContext = useUserContext();

  if (!userContext || !userContext.teamId) {
    return <></>;
  }

  return <>
    <h2>Hi {userContext.firstName}!</h2>

    <QuickFunctions userRole={userContext.userRole} />

    <Panels>
      <TeamPanel userContext={userContext} />
      <ReviewsPanel userContext={userContext} />
      <ReportsPanel userContext={userContext} />
    </Panels>
  </>;
};
