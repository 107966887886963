import React from 'react';

import { faListUl, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';

import Button from '../../button';
import { Panel } from '../../responsive-panels';
import List from '../../list';

import './invitations.css';

const InvitationContent = ({ invitations }) => {
  if (!invitations.length) {
    return <>
      <div className="invitations-panel__none__icon">
        <FontAwesomeIcon icon={faEnvelope} />
      </div>
      <div className="invitations-panel__none__text">
        No Open Invitations
      </div>
    </>;
  }

  return <List additionalItemClass="invitations__panel__list__item" items={invitations.map(({
    username,
    email,
    lastInviteSent,
  }) => {
    const expiresOn = lastInviteSent ? DateTime.fromISO(lastInviteSent).plus({ days: 7 }) : undefined;
    const isExpired = expiresOn && expiresOn.diffNow() < 0;

    return {
      icon: faEnvelope,
      title: email,
      key: username,
      className: isExpired ? ' invitation-expired' : '',
      link: `/team/member/${username}`,
      content: <>
        <div className="invitations__panel__list__item__lastInvited" title={email}>
          {expiresOn ? `${isExpired ? 'Expired' : 'Expires'} ${expiresOn.toRelative()}` : ''}
        </div>
      </>,
    };
  })} />;
};

export default ({ userContext: { teamId } }) => {
  return <Panel
    title="Invitations"
    apiEndpoint={`team/${teamId}/invitations`}
    content={({ invitations =[] } = {}) => <InvitationContent invitations={invitations} />}
    buttons={({ invitations =[] } = {}) => [
    ...(invitations && invitations.length ? [<Button icon={faListUl} text="Show All" link="/team/invitations" />] : []),
      <Button icon={faUserPlus} text="Invite Team Member" link="/team/invite" />,
    ]} />;
};
