import React, { Component } from 'react';

import { Authenticator, SignUp, Greetings } from "aws-amplify-react";
import Amplify, { Auth, Hub } from "aws-amplify";
import { BrowserRouter } from "react-router-dom";

import Routes from "./routes";

import LoggedOut from './components/logged-out';
import TopBar from './components/top-bar';
import FooterBar from './components/footer-bar';
import Loading from './components/loading';

import './App.css';

Amplify.configure({
    Analytics: {
      disabled: true,
    },
    Auth: {
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      region: process.env.REACT_APP_IDENTITY_REGION,
      userPoolId: process.env.REACT_APP_IDENTITY_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_IDENTITY_USER_POOL_WEBCLIENT_ID,
    },
    API: {
      endpoints: [
        {
          name: "api",
          endpoint: process.env.REACT_APP_API_ENDPOINT,
          custom_header: async () => {
            const session = await Auth.currentSession();
            return { Authorization: session.idToken.jwtToken };
          },
        },
        {
          name: "measureitnow",
          endpoint: process.env.REACT_APP_MEASUREITNOW_API_ENDPOINT,
          custom_header: async () => {
            const session = await Auth.currentSession();
            return { Authorization: session.idToken.jwtToken };
          },
        }
      ],
    },
});

class AppPanel extends Component {
  render() {
    if (this.props.authState !== 'signedIn') {
      return <></>;
    }

    if (!this.props.userContext) {
      return <Loading />;
    }

    return (
      <BrowserRouter>
        <TopBar userContext={this.props.userContext} />
        <div className="main-area">
          <Routes userContext={this.props.userContext} />
        </div>
        <FooterBar />
      </BrowserRouter>
    );
  }
}

class App extends Component {
  constructor() {
    super();

    Hub.listen('auth', (data) => {
      if (data.payload.event === 'signIn') {
        const idData = data.payload.data.signInUserSession.idToken.payload;
        this.setUserContext(idData);
      }
    });

    this.state = {};
  }
  
  setUserContext(userContext) {
    this.setState({ userContext });
  }

  componentDidMount() {
    Auth.currentSession().then(session => this.setUserContext(session.idToken.payload));
  }

  userDetailsCompleted() {
    Auth.currentSession().then(session => this.setUserContext(session.idToken.payload));
  }

  render() {
    const AuthTheme = {
      googleSignInButton: { backgroundColor: "red", borderColor: "red" },
      button: { backgroundColor: "var(--color-blue)", borderColor: "var(--color-blue)", borderRadius: '5px' },
      a: { color: 'var(--color-blue)' },
    };
  
    return (
      <>
        {!this.state.userContext ? <LoggedOut /> : <></>}
        <Authenticator theme={AuthTheme} hide={[SignUp, Greetings]}>
          <AppPanel userContext={this.state.userContext} />
        </Authenticator>
      </>
    );
  }
}

export default App;
