import React from 'react';

import PropagateLoader from 'react-spinners/PropagateLoader';

import './loading.css';

export default () => <div className="loading-wrapper">
  <PropagateLoader
    sizeUnit={"px"}
    size={20}
    color={'#007EB9'}
    loading={true}
  />
</div>;
