import React from 'react';

import FieldSet from './field-set';
import InfiniteForm from './infinite-form';
import Matrix from './matrix';
import Panels from './panels';
import ValueList from './value-list';

const sectionRenderers = {
  fieldSet: (...props) => <FieldSet {...Object.assign(...props)} />,
  infiniteForm: (...props) => <InfiniteForm {...Object.assign(...props)} />,
  matrix: (...props) => <Matrix {...Object.assign(...props)} />,
  panelSelector: (...props) => <Panels {...Object.assign(...props)} />,
  valueList: (...props) => <ValueList {...Object.assign(...props)} />,
};

export default (section, ...props) => {
  const { type, ...sectionConfig } = section;
  const { index } = Object.assign(...props);

  if (!sectionRenderers[type]) {
    return <></>;
  }

  return sectionRenderers[type](sectionConfig, ...props, { key: sectionConfig.id || `${type}__${index}` });
};
