import React from 'react';

import DynamicColumnTable from '../../dynamic-column-table';
import createGraphVisualisation from '../../visualisations';

const VoltageDropGraph = ({ data, width }) => {
  const mean = data.readings.reduce((total, voltageDrop) => total + voltageDrop, 0) / data.readings.length;
  // const upperThreshold = 22;
  // const lowerThreshold = 14;
  // const valuesOfRange = data.data.filter(({ drop }) => !(drop <= upperThreshold && drop >= lowerThreshold
  //   && drop >= lowerThreshold && drop <= upperThreshold)).length
  // const result = mean <= upperThreshold && mean >= lowerThreshold
  //   && mean >= lowerThreshold && mean <= upperThreshold;
  // const upperValue = mean + (1 * data.supplyDropStandardDeviation);
  // const lowerValue = mean - (1 * data.supplyDropStandardDeviation);
  // const sdResult = upperValue <= upperThreshold && upperValue >= lowerThreshold
  //   && lowerValue >= lowerThreshold && lowerValue <= upperThreshold;

  const visualisation = createGraphVisualisation()
    .setLabels(data.readings.map((item, i) => `${i}`))
    .addScatterPoints(data.readings.map((voltageDrop) => voltageDrop), {
      label: 'Voltage Drop',
      color: '#82D8FF',
    })
    .addHorizontalLine(mean, {
      label: 'Drop Average',
      color: 'black',
    });

  const height = width > 700 ? 500 : 300;

  return <>
    <div className="view-report__graph-container" style={{ height: `${height}px` }}>
      {visualisation.createChart({ height })}
    </div>
  </>;
};

export default ({ report, width }) => {
  const voltageDrop = report['voltage-drop'];
  const { reference } = report;
  if (!voltageDrop) {
    return <div>
      <h2>Section not completed</h2>
    </div>;
  }

  const { readings } = voltageDrop;

  return <div>
    <VoltageDropGraph  data={voltageDrop} reference={reference} width={width} />
    <h2>Readings</h2>
    <DynamicColumnTable tableName="voltage-drop" items={readings.map(value => ({ voltageDrop: value }))} columns={[{ value: 'voltageDrop', width: 50 }]} maxColumnSets={4}  />
  </div>;
};
