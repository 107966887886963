import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Home from "./pages/home";
import Reports from "./pages/reports";
import NewReport from "./pages/new-report";
import ViewReport from "./pages/view-report";
import Team from "./pages/team-management";
import TeamMembers from "./pages/team-management/members";
import Invitations from "./pages/team-management/invitations";
import UserDetails from "./pages/team-management/user-details";
import EditUser from "./pages/team-management/edit-user";
import TeamInvite from './pages/team-management/invite-user';
import TeamUsage from './pages/team-management/usage';
import Forbidden from "./pages/error/forbidden";
import FirstTime from "./pages/profile/first-time";
import Profile from "./pages/profile";

export default ({ userContext }) => {
  if (userContext.userRole === 'invited') {
    return <Switch>
      <Route path="/first-time" exact component={FirstTime} />
      <Route path="/"><Redirect to="/first-time" /></Route>
    </Switch>;
  }

  return <Switch>
    <Route path="/" exact component={Home} />
    <Route path="/first-time" exact component={Home} />
    <Route path="/profile" exact component={Profile} />
    <Route path="/team" exact component={Team} />
    <Route path="/reports" exact component={Reports} />
    <Route path="/report/new" exact component={NewReport} />
    <Route path="/report/:reference" exact component={ViewReport} />
    <Route path="/team/members" exact component={TeamMembers} />
    <Route path="/team/invitations" exact component={Invitations} />
    <Route path="/team/member/:id" exact component={UserDetails} />
    <Route path="/team/member/:id/edit" exact component={EditUser} />
    <Route path="/team/invite" exact component={TeamInvite} />
    <Route path="/team/usage" exact component={TeamUsage} />
    <Route path="/403" exact component={Forbidden} />
  </Switch>;
};
