import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import vis from 'vis'
import uuid from 'uuid'
import isEqual from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';

class Graph3D extends Component {

  constructor(props) {
    super(props)

    const {identifier} = props
    this.state = {
      identifier: identifier !== undefined ? identifier : uuid.v4()
    }

    this.updateGraph = this.updateGraph.bind(this)
  }

  componentDidMount() {
    const container = document.getElementById(this.state.identifier)
    this.$el = new vis.Graph3d(container, undefined, this.props.options)
    this.$el.on('cameraPositionChange', this.props.cameraPositionChangeHandler)
    this.updateGraph()
  }

  shouldComponentUpdate(nextProps) {
    const {options, data} = this.props

    const optionsChange = !isEqual(options, nextProps.options) || options.length !== nextProps.options.length
    const dataChange = !isEqual(data, nextProps.data) || data.length !== nextProps.data.length
    return(optionsChange || dataChange)
  }

  componentDidUpdate() {
    this.updateGraph()
  }

  updateGraph() {
    var data = new vis.DataSet()
    data.add(this.props.data)
    this.$el.setData(data)
    this.$el.setOptions(this.props.options)
    this.$el.redraw()
  }

  render() {
    const {identifier} = this.state

    return React.createElement(
      "div",
      {
        id: identifier,
      }
    )
  }
}

Graph3D.defaultProps = {
  options: {
    width:  '600px',
    height: '600px',
    style: 'grid',
    showPerspective: true,
    showGrid: true,
    showShadow: false,
    keepAspectRatio: true,
    verticalRatio: 0.5,
    backgroundColor: { fill: 'white' },
  },
  cameraPositionChangeHandler: function() {}
}

Graph3D.propTypes = {
  options: PropTypes.object,
  data: PropTypes.array.isRequired,
  cameraPositionChangeHandler: PropTypes.func
}

export default ({ measurements, reference, options = {} }) => {
  const [graphSettings] = useState({ verticalRatio: 0.1 });

  const data = measurements.reduce((all, column, columnI) => [
    ...all,
    ...column.map((value, rowI) => ({
      x: columnI,
      y: rowI,
      z: value,
      style: 255,
    })),
  ], []);

  const graphOptions = {
    xStep: 1,
    yStep: 1,
    yCenter: '40%',
    width:  '100%',
    height: '380px',
    style: 'surface',
    showPerspective: true,
    showGrid: true,
    showShadow: false,
    keepAspectRatio: true,
    ...options,
    ...graphSettings
  };

  const saveGraph = (e) => {
    const containerDiv = e.target.closest('.foundations__graph-container-inner');
    const element = containerDiv.querySelector('canvas')
    const context = element.getContext('2d');
    const canvas = context.canvas;
    const w = canvas.width;
    const h = canvas.height;
    const data = context.getImageData(0, 0, w, h);
    const compositeOperation = context.globalCompositeOperation;

    context.globalCompositeOperation = "destination-over";
    context.fillStyle = '#ffffff';
    context.fillRect(0,0,w,h);

    const uri = element.toDataURL("image/jpeg");

    context.clearRect (0,0,w,h);
    context.putImageData(data, 0,0);
    context.globalCompositeOperation = compositeOperation;

    const link = document.createElement("a");
    link.download = `${reference}-foundations.jpg`;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return <div className="foundations__graph-container-inner">
    <Graph3D data={data} options={graphOptions} />
    <div className="foundations__graph-save button" onClick={saveGraph}><FontAwesomeIcon icon={faFileDownload} /> Save</div>
  </div>;
};
