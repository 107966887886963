import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './error.css';

export default ({ title, icon, content }) => <div className="error-page__container">
  <div className="error-page__icon">
    <FontAwesomeIcon icon={icon} />
  </div>
  <h2>{title}</h2>
  {content}
</div>;
