import React from 'react';

import { faUsersCog } from '@fortawesome/free-solid-svg-icons';

import Button from '../../button';
import PlanSummary from '../../plan-summary';
import { Panel } from '../../responsive-panels';

export default ({ userContext: { teamName, teamId, userRole } }) => {
  return <Panel
    id="home_team_panel"
    title={teamName || ''}
    apiEndpoint={`team/${teamId}/usage`}
    content={(planSummary) => <PlanSummary planSummary={planSummary} />}
    buttons={[
      <Button icon={faUsersCog} text="Manage Team" link="/team" key="team__panel__manage-button" />,
    ]}
    userRole={userRole} permittedRoles={['system-admin', 'owner', 'admin']} />;
};
