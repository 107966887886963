import React from 'react';

import { Link } from 'react-router-dom';
import { faBug } from '@fortawesome/free-solid-svg-icons';

import Error from '../../components/error';

export default () => <Error
  icon={faBug}
  title="Sorry!"
  content={<>So sorry, we're not sure what's happened there, try going back to the <Link to="/">main screen</Link>, if you continue to have issues then please let us know, providing error code: <b>ketchup</b></>}
  />;
