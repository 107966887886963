import React from 'react';

import { Auth } from 'aws-amplify';
import { Formik, Form } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCheck, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

import FormField from '../../components/form/form-field';
import SubmitButton from '../../components/button/submit-button';
import StatusMessage from '../../components/form/status-message';

import useUserContext from '../../user-context';
import './profile.css';

const passwordPolicyItems = {
  MinimumLength: {
    test: input => input.length > 7,
    text: 'Be at least 8 characters long',
  },
  ContainNumber: {
    test: input => /[0-9]/.exec(input),
    text: 'Contain at least one number',
  },
  ContainLowerCase: {
    test: input => /[a-z]/.exec(input),
    text: 'Contain at least one lower case character',
  },
  ContainUpperCase: {
    test: input => /[A-Z]/.exec(input),
    text: 'Contain at least one upper case character',
  },
};

const newPasswordIsInValid = (password) => {
  if (!password) {
    return 'You must enter a new password';
  }

  const failedPolicyOptions = Object.entries(passwordPolicyItems).reduce((all, [key, { test }]) => {
    const failed = !test(password);

    if (failed) {
      return [...all, key];
    }

    return all;
  }, []);


  if (failedPolicyOptions.includes('minLength')) {
    return 'New password must be at least 8 characters long';
  }

  if (failedPolicyOptions.length) {
    return 'New password must contain at least one number, upper and lower case characters';
  }

  return false;
};

const PasswordPolicyChecklist = ({ password, warning = false }) => {
  const incompleteIcon = warning ? faExclamationTriangle : faCircle;
  const incompleteClass = warning ? ' warning' : '';

  return <div className="user-details__password-policy">
    Your new password must:
    <ul className="user-details__password-policy__checklist">
      {Object.entries(passwordPolicyItems).map(([key, { test, text }]) => {
        const policyItemMatched = test(password);
        const icon = policyItemMatched ? faCheck : incompleteIcon;
        const classes = `user-details__password-policy__checklist__item${
          policyItemMatched
            ? ' completed'
            : incompleteClass
        }`;

        return <li
          key={`password-policy__${key}`}
          className={classes}>
            <div className="user-details__password-policy__checklist__item__icon">
              <FontAwesomeIcon icon={icon} />
            </div>
            <div className="user-details__password-policy__checklist__item__text">{text}</div>
          </li>;
      })}
    </ul>
  </div>;
};

const getMatches = (errorString) => {
  const re = /([^']+)' failed to satisfy constraint: ([^;]+)/ig;
  const [, ...matches] = re.exec(errorString);

  return {
    matches,
    rest: errorString.substring(errorString.indexOf(matches[1]) + matches[1].length + 2),
  };
};

const fieldErrorMappings = {
  previousPassword: 'current_password',
  proposedPassword: 'new_password',
};

const getFieldErrors = (msg) => {
  let remaining = msg;
  let errors = {};

  while(remaining) {
    const { matches, rest } = getMatches(remaining);

    if (matches) {
      const field = fieldErrorMappings[matches[0]]
        ? fieldErrorMappings[matches[0]]
        : matches[0];
      errors[field] = matches[1];
    }

    remaining = rest;
  }


  return errors;
}

const handleSubmit = async (values,
  {
    setSubmitting,
    setStatus,
    setValues,
    setTouched,
    setErrors
  }) => {
    setSubmitting(true);

    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, values.current_password, values.new_password);

      setStatus({ state: 'success', text: 'Password Updated' });
      setValues({
        current_password: '',
        new_password: '',
        new_password_confirm: '',
      });
      setTouched({
        current_password: false,
        new_password: false,
        new_password_confirm: false,
      })
    } catch (e) {
      console.log(e);
      if (e.code === 'InvalidParameterException') {
        const parsedErrors = getFieldErrors(e.message);

        if (!parsedErrors || !Object.keys(parsedErrors).length) {
          setStatus(e.message);
        }
        else {
          setErrors(parsedErrors);
        }
      } else if (e.code === 'NotAuthorizedException') {
        setErrors({ current_password: 'Current password incorrect' });
      }
      else {
        setStatus({ state: 'error', text: e.message });
      }
    }

    setSubmitting(false);
}

export default () => {
  const userContext = useUserContext();

  if (!userContext) {
    return <div />;
  }

  return <div className="profile-container">
    <div className="profile-container__item">
      <h2><FontAwesomeIcon icon={faUser} /> Profile</h2>

      <Formik
        initialValues={{
          current_password: '',
          new_password: '',
          new_password_confirm: '',
        }}
        validate={values => {
          let errors = {};

          if (!values.current_password) {
            errors.current_password = 'You must enter your current password';
          }

          const newPasswordError = newPasswordIsInValid(values.new_password);
          if (newPasswordError) {
            errors.new_password = newPasswordError;
          }

          if (values.new_password_confirm !== values.new_password) {
            errors.new_password_confirm = 'New passwords do not match';
          }

          return errors;
        }}
        onSubmit={handleSubmit}
        render={({ values, errors, status = {}, touched, isSubmitting }) => (
          <Form>
            <div className="user-details__container">
              <h3>Update Password</h3>

              <PasswordPolicyChecklist warning={errors.new_password && touched.new_password} password={values.new_password} />

              <StatusMessage text={status.text} state={status.state} />

              <div className="user-details__form">
                <FormField
                  errors={errors}
                  touched={touched}
                  type="password"
                  label="Current Password"
                  name="current_password" />

                <FormField
                  errors={errors}
                  touched={touched}
                  type="password"
                  label="New Password"
                  name="new_password" />

                <FormField
                  errors={errors}
                  touched={touched}
                  type="password"
                  label="Confirm New Password"
                  name="new_password_confirm" />
              </div>
              <div className="user-details__button">
                <SubmitButton isSubmitting={isSubmitting} text="Change Password" />
              </div>
            </div>
          </Form>)} />
    </div>
  </div>;
};
