import React from 'react';
import { useHistory } from 'react-router-dom';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import Button from '../button';

import './quick-functions.css';

export default ({ userRole, buttons = [] }) => {
  const history = useHistory();

  return <ul className="quick-functions">
    {buttons.map(({
      permittedRoles = [],
      type,
      text,
      ...buttonProps
    }, i) => {
      if (permittedRoles.length && !permittedRoles.includes(userRole)) {
        return <></>;
      }
      
      if (type === 'back') {
        return <li className={!i ? 'quick-functions__item__first' : ''} key="back">
          <Button text={text || 'Back'} icon={faChevronLeft} onClick={() => history.goBack()} />
        </li>;
      }

      return <li className={!i ? 'quick-functions__item__first' : ''} key={text.replace(/ /ig, '-').toLowerCase()}>
        <Button text={text} type={type} {...buttonProps} />
      </li>;
    })}
  </ul>;
};
