import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlask } from '@fortawesome/free-solid-svg-icons';

import './footer-bar.css';

const EnvironmentInfo = () => {
  if (process.env.REACT_APP_SHOW_ENVIRONMENT_INFO !== 'true') {
    return <></>;
  }

  const versionString = process.env.REACT_APP_VERSION === 'development' || !process.env.REACT_APP_BUILD_DATE
    ? process.env.REACT_APP_VERSION
    : `${process.env.REACT_APP_VERSION} (${process.env.REACT_APP_BUILD_DATE})`;

  return <ul className="footer-bar__environment-info">
    <li className="footer-bar__environment-info__icon"><FontAwesomeIcon icon={faFlask} /></li>
    <li className="footer-bar__environment-info__item">Environment: {process.env.REACT_APP_DEPLOYMENT_NAME || ''}</li>
    <li className="footer-bar__environment-info__item">Version: {versionString}</li>
    <li className="footer-bar__environment-info__item">API Endpoint: {process.env.REACT_APP_API_ENDPOINT || ''}</li>
  </ul>
};

export default () => {
  const currentYear = new Date().getFullYear();

  return <div className="footer-bar">
    <EnvironmentInfo />
    <div className="footer-bar__copyright-notice">Copyright &copy; {currentYear === 2019 ? currentYear : `2019 - ${currentYear}`} Measure It Now! All rights reserved.</div>
  </div>;
};
