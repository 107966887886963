import React, { useState } from 'react';

import { faFilter, faSearch } from '@fortawesome/free-solid-svg-icons';
import { DateTime } from 'luxon';
import { useHistory, useLocation } from "react-router-dom";

import Button from '../../components/button';
import Tabs from '../../components/tabs';

const updateHistory = (history, options) => {
  const optionEntries = Object.entries(options);
  const currentUrl = `${document.location.pathname}${document.location.search ? document.location.search : ''}${document.location.hash || ''}`;
  const targetUrl = optionEntries.length
    ? `${document.location.pathname}?${optionEntries.filter(([, value]) => typeof value !== 'undefined').map(([key, value]) => `${key}=${value}`).join('&')}${document.location.hash}`
    : `${document.location.pathname}${document.location.hash}`;

  if (currentUrl !== targetUrl) {
    history.push(
      targetUrl,
      options,
    );
  }
};

const OptionList = ({ options = [], name, selectedValue, onChange }) => {
  return <select name={name} onChange={onChange} value={selectedValue || ''}>
    {options.map(({ value, text }) => <option value={value} key={`${name}__${value}`}>{text}</option>)}
  </select>;
};

const getParametersFromLocation = ({ search }) => search
  ? decodeURI(search.substring(1))
    .split('&')
    .reduce((all, item) => {
      const [key, value] = item.split('=');

      all[key] = value;

      return all;
    }, { })
  : { };

export default ({ events = { }, teamMembers = [], canViewTeamReports = false }) => {
  const history = useHistory();
  const location = useLocation();

  const locationParameters = getParametersFromLocation(location);
  const defaultTab = locationParameters.searchType || 'filter';

  const [showAdvancedDate, setShowAdvancedDate] = useState(false);
  const [currentTab, setCurrentTab] = useState(defaultTab);
  const [fieldValues, setFieldValues] = useState(locationParameters);

  let setTab;

  React.useEffect(() => {
    history.listen((location) => {
      const newParametersFromLocation = getParametersFromLocation(location);
      const { searchType = 'filter' } = newParametersFromLocation;
      
      setFieldValues(newParametersFromLocation);
      doSearch(newParametersFromLocation, searchType, true);

      if (setTab) {
        setTab(searchType);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const filterValueUpdate = (setKey, newValue) => {
    const newFilterOptions = {
      ...fieldValues,
    };
  
    newFilterOptions[setKey] = newValue;

    setFieldValues(newFilterOptions);
  };

  const setFilters = (parameters = fieldValues) => {
    doSearch();
    updateHistory(history, {
      ...parameters,
      ...(currentTab === 'search' ? { searchType: currentTab } : { searchType: undefined }),
    });
  };

  const doSearch = (parameters = fieldValues, tab = currentTab, force = false) => {
    const relevantKeys = tab === 'search'
      ? ['reference']
      : ['userRole', 'username', 'month', 'sortBy'];

    if (events.filterUpdate) {
      const searchParameters = relevantKeys.reduce((all, key) => {
        if (!parameters[key]) {
          return all;
        }

        all[key] = parameters[key];

        return all;
      }, { searchType: tab });

      events.filterUpdate(searchParameters, force);
    }
  };

  const monthList = [{ value: '', text: 'All' }];
  const start = DateTime.local();
  const end = DateTime.fromISO('2019-04-01');

  const [keyDownTimeout, setKeyDownTimeout] = useState();

  for (let x = 0; x < start.diff(end, 'months').toObject().months;x++) {
    const current = start.minus({ month: x });
    monthList.push({
      value: current.toLocaleString({ month: 'numeric', year: 'numeric' }),
      text: current.toLocaleString({ month: 'long', year: 'numeric' }),
    });
  }

  return <div className="filter">
    <Tabs defaultTab={defaultTab}
      setTab={(changeTabFunction) => setTab = changeTabFunction}
      onChange={(newTab) => {
        setCurrentTab(newTab);
      }}
      tabs={[
        {
          title: 'Filter',
          icon: faFilter,
          content: <>
            {canViewTeamReports
              ? <label className="reports__filter__item">
                Inspector: 
                <OptionList name="username" options={[
                  { value: '', text: 'All' },
                  ...teamMembers.map(({ username, firstName, lastName }) => ({ value: username, text: `${firstName} ${lastName}` }))
                ]} selectedValue={fieldValues.username} onChange={(e) => {
                  filterValueUpdate('username', e.target.value);
                }} />
              </label>
              : <></>}
            

            {showAdvancedDate
              ? <>
              <label className="reports__filter__item">
                From: 
                <input type="date" />
              </label>
              <label className="reports__filter__item"reports__>
                To: 
                <input type="date" />
              </label>

              <div className="reports__filter__item filter__item__advanced-date">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="#" onClick={() => setShowAdvancedDate(false)}>Set Month</a>
              </div></>
              : <><label className="reports__filter__item">
                Month: 
                <OptionList name="month" options={monthList} selectedValue={fieldValues.month} onChange={(e) => {
                  filterValueUpdate('month', e.target.value);
                }} />
              </label>

              {/* <div className="reports__filter__item filter__item__advanced-date">
                <a href="#" onClick={() => setShowAdvancedDate(true)}>Set From/To</a>
              </div> */}
            </>}

            <label className="reports__filter__item">
              Sort by:
              <OptionList name="sortBy" options={[
                { value: 'created_desc', text: 'Newest First' },
                { value: 'created_asc', text: 'Oldest First' },
              ]} selectedValue={fieldValues.sortBy} onChange={(e) => {
                filterValueUpdate('sortBy', e.target.value);
              }} />
            </label>

            <div className="reports__search__buttons">
              <Button icon={faFilter} onClick={() => setFilters()}>Filter</Button>
            </div>
          </>,
        },
        {
          title: 'Search',
          icon: faSearch,
          content: <>
            <label className="reports__filter__item">
              Reference: 
              <input
                type="text"
                className="reports__search__reference"
                defaultValue={fieldValues.reference}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    if (keyDownTimeout) {
                      return;
                    }

                    setFilters();
                    setKeyDownTimeout(setTimeout(() => {
                      setKeyDownTimeout(undefined);
                    }, 2500));
                  }
                }}
                onChange={(e) => {
                  filterValueUpdate('reference', e.target.value);
                }} />
            </label>

            <div className="reports__search__buttons">
              <Button icon={faSearch} onClick={() => setFilters()}>Search</Button>
            </div>
          </>,
        },
      ]} />
  </div>;
};
