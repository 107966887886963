import React from 'react';

import DynamicColumnTable from '../../dynamic-column-table';
import createGraphVisualisation from '../../visualisations';

import './ac.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const AcDiscrepency = ({ section: { pass = true } }) => pass ? <></> : <div className="discrepency__section">
  <div className="discrepency__section__icon">
    <FontAwesomeIcon icon={faExclamationTriangle} />
  </div>
  <div className="discrepency__section__text">
    Recorded values were outside expected range, recommend reviewing report
  </div>
</div>;

const ACScatterGraph = ({ ac, width }) => {
  if (typeof width === 'undefined') {
    return <></>;
  }

  const visualisation = createGraphVisualisation()
    .setLabels(ac.supplyValues.map((item, i) => `${i}`))
    .addScatterPoints(ac.supplyValues.map(({ drop }) => drop ? drop.toFixed(2) : drop), {
      label: 'Temperature Drop',
      color: '#82D8FF',
    })
    .addHorizontalLine(ac.supplyDropMean, {
      label: 'Drop Average',
      color: 'black',
    })
    .addShadedArea({
      upper: ac.acceptableRange.finish,
      lower: ac.acceptableRange.start,
    }, {
      label: 'Pass Zone',
      color: '#22EB4B',
      backgroundColor: 'rgba(131, 255, 139, 0.4)',
    });

  const height = width > 700 ? 500 : 300;

  return <>
    <div className="view-report__graph-container" style={{ height: `${height}px` }}>
      {visualisation.createChart(height)}
    </div>
  </>;
};

export default ({ report: { ac, reference }, width }) => {
  if (!ac || !Object.keys(ac).length) {
    return <div>
      <h2>Section not completed</h2>
    </div>;
  }

  return <div>
    <AcDiscrepency section={ac} />

    <ACScatterGraph ac={ac} reference={reference} width={width} />

    <div className="ac__container">
      <h2>Return Readings</h2>
      <DynamicColumnTable
        tableName="ac-return"
        items={ac.returnValues.map(value => ({ tempReading: value }))}
        columns={[{ value: 'tempReading', width: 75, heading: 'Temp' }]}
        maxColumnSets={1} />
    </div>

    <div className="ac__container">
      <h2>Supply Readings</h2>
      <DynamicColumnTable
        tableName="ac-supply"
        items={ac.supplyValues}
        columns={[
          { value: 'reading', width: 75, heading: 'Temp' },
          { value: 'drop', width: 75, formatter: value => value ? value.toFixed(2) : value, heading: 'Drop' },
        ]}
        maxColumnSets={3} />
    </div>
  </div>;
};
