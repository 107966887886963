import React from 'react';

import { faListUl, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from '../../button';
import List from '../../list';
import { Panel } from '../../responsive-panels';
import UserRoleIcon from '../../icons/user-role-icon';
import { kebabToSentenceCase } from '../../../utilities/string';

import './team-members.css';

const formatUserRole = (role) => kebabToSentenceCase(role);

const PanelContent = ({ teamMembers }) => {
  return <List additionalItemClass="team-members__panel__list__item" items={teamMembers.teamMembers.map(({
    username,
    firstName,
    lastName,
    userRole,
    email,
  }) => ({
    icon: <UserRoleIcon role={userRole} />,
    title: `${firstName} ${lastName}`,
    key: username,
    link: `/team/member/${username}`,
    content: <>
      <div>{formatUserRole(userRole)}</div>
      <div className="team-members__panel__list__item__email" title={email}>
        <FontAwesomeIcon icon={faEnvelope} /> {email}
      </div>
    </>,
  }))} />;
};

export default ({ userContext: { teamId } }) => {
  return <Panel
    title="Team Members"
    apiEndpoint={`team/${teamId}/members`}
    content={(teamMembers) => <PanelContent teamMembers={teamMembers} />}
    buttons={[
      <Button icon={faListUl} text="Show All" link="/team/members" />,
    ]} />;
};
