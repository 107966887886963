import React, { useEffect, useState } from 'react';

import { API } from "aws-amplify";
import { faFileAlt, faMapMarkerAlt, faUser, faCalendarAlt, faFileMedical } from '@fortawesome/free-solid-svg-icons';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from "react-router-dom";
import uuidv4 from "uuid/v4";

import Filters from './filters';
import ResponsiveList from '../../components/responsive-list';
import QuickFunctions from '../../components/quick-functions';
import Loading from '../../components/loading';
import useUserContext from '../../user-context';

import './reports.css';

const defaultQueryOptions = { userRole: 'all', sortBy: 'userRole_desc' };

const columns = [
  { header: 'Reference', isHeader: true, width: 220, value: 'reference' },
  {
    header: 'Created',
    width: 230,
    required: true,
    icon: <FontAwesomeIcon icon={faCalendarAlt} />,
    value: ({ created }) => {
      if (!created) {
        return 'Created Unknown';
      }

      return <>{DateTime.fromISO(created).toRelative()}<br/>({DateTime.fromISO(created).toLocaleString(DateTime.DATETIME_SHORT)})</>;
    },
  },
  {
    header: 'Author',
    width: 230,
    required: true,
    icon: <FontAwesomeIcon icon={faUser} />,
    value: ({ userFullName }) => {
      if (!userFullName) {
        return 'Author Unknown';
      }

      return <>{userFullName}</>;
    },
  },
  {
    header: 'Address',
    width: 230,
    required: false,
    icon: <FontAwesomeIcon icon={faMapMarkerAlt} />,
    value: ({ address, zip }) => {
      if (!address) {
        return 'Address Unknown';
      }

      return <>{address}<br />{zip}</>;
    },
  },
];

const getParametersFromLocation = ({ search }) => {
  const parameters = search
    ? decodeURI(search.substring(1))
      .split('&')
      .reduce((all, item) => {
        const [key, value] = item.split('=');

        all[key] = value;

        return all;
      }, { })
    : { };

  const relevantKeys = parameters.searchType === 'search'
    ? ['reference']
    : ['userRole', 'username', 'month', 'sortBy'];

  return relevantKeys.reduce((all, key) => {
    if (!parameters[key]) {
      return all;
    }

    all[key] = parameters[key];

    return all;
  }, {});
};

const buildQuerystring = (increment, updatedOptions = {}, pagination = {}) => {
  const searchParams = {
    ...(updatedOptions.length ? Object.fromEntries(updatedOptions) : updatedOptions),
    ...(increment ? { increment } : {}),
    ...pagination,
  };

  const entries = Object.entries(searchParams);

  if (!entries.length) {
    return '';
  }

  return `?${entries.map(([key, value]) => {
    if (key === 'lastKey') {
      return `${key}=${Object.entries(value).map(([lastKeyKey, lastKeyValue]) => `${lastKeyKey}:${lastKeyValue}`).join('|')}`;
    }

    return `${key}=${value}`;
  }).join('&')}`;
};

const ReportList = ({ userContext: { teamId }, canViewTeamReports = false }) => {
  const location = useLocation();

  const [reportData, updateReports] = useState();
  const [teamMemberData, updateTeamMembers] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [increment, setIncrement] = useState(0);
  const [searchParameters, setSearchParameters] = useState(getParametersFromLocation(location));
  const [pagination, setPagination] = useState({ limit: 20 });

  let onLoadCompleteFunction;
  let showInlineLoadingFunction;

  function useFetchReports(url) {
    useEffect(() => {
      const getData = async () => {
        const resp = await API.get('api', url);

        setLoadingMore(false);

        if (pagination.lastKey) {
          updateReports({
            ...resp,
            items: [
              ...reportData.items,
              ...resp.items,
            ],
          });
        } else {
          updateReports(resp);
        }

        if (teamMemberData || !canViewTeamReports) {
          setLoading(false);
        }

        if (onLoadCompleteFunction) {
          onLoadCompleteFunction();
        }
      };
      getData();
    }, [url]);

    return reportData;
  }
  
  function useFetchTeamMembers(url) {
    useEffect(() => {
      const getData = async () => {
        const resp = await API.get('api', url);

        updateTeamMembers(resp);

        if (reportData) {
          setLoading(false);
        }

        if (onLoadCompleteFunction) {
          onLoadCompleteFunction();
        }
      };
      getData();
    }, [url]);

    return teamMemberData;
  }

  const reports = useFetchReports(`${canViewTeamReports ? `team/${teamId}` : 'me'}/reports${buildQuerystring(increment, searchParameters, pagination)}`);
  let teamMembers;
  if (canViewTeamReports) {
    teamMembers = useFetchTeamMembers(`team/${teamId}/members?sortBy=userName`); // eslint-disable-line react-hooks/rules-of-hooks
  }

  const loadMoreItems = () => {
    setLoadingMore(true);
    setPagination({
      ...pagination,
      lastKey: reports.lastKey,
    });
  };

  const refresh = () => {
    setPagination({ limit: pagination.limit });
    setIncrement(uuidv4());
  };

  const filterUpdate = (newFilter) => {
    const relevantParameters = newFilter.searchType === 'search'
      ? ['reference']
      : ['username', 'month', 'sortBy'];
    const newSearchParameters = Object.entries(newFilter).reduce((all, [key, value]) => {
      if (!relevantParameters.includes(key) || !value || value === defaultQueryOptions[key]) {
        return all;
      }
  
      return [...all, [key, value]];
    }, []);

    showInlineLoadingFunction();
    setPagination({ limit: pagination.limit });
    setSearchParameters(Object.fromEntries([
      ...newSearchParameters,
      ['increment', uuidv4()],
    ]));
  }

  const onLoadComplete = (newOnLoadCompleteFunction) => {
    onLoadCompleteFunction = newOnLoadCompleteFunction;
  };

  const onLoadStart = (newOnLoadStartFunction) => {
    showInlineLoadingFunction = newOnLoadStartFunction;
  }

  if (loading || !reports || (canViewTeamReports && !teamMembers)) {
    return <div className="loading-wrapper">
      <Loading height={100} />
    </div>;
  }

  return <>
    <Filters events={{ filterUpdate }} canViewTeamReports={canViewTeamReports} teamMembers={teamMembers ? teamMembers.teamMembers : {}} />

    <ResponsiveList
      items={reports.items.map(report => ({
        key: report.reference,
        link: `/report/${report.reference}`,
        icon: <FontAwesomeIcon icon={faFileAlt} />,
        ...report,
      }))}
      moreItemsAvailable={reports.lastKey ? true : false}
      loadingMoreItems={loadingMore}
      columns={columns}
      itemName="report"
      events={{ refresh, onLoadStart, onLoadComplete, loadMoreItems }}
    />
  </>;
};

export default () => {
  const userContext = useUserContext();

  if(!userContext || !userContext.teamId) {
    return <></>;
  }

  const canViewTeamReports = ['admin', 'owner', 'system-admin'].includes(userContext.userRole);

  return <>
    <h2>Reports</h2>

    <QuickFunctions buttons={[
      { icon: faFileMedical, text: 'New Report', link: '/report/new' },
    ]} />

    <ReportList userContext={userContext} username={userContext.username} canViewTeamReports={canViewTeamReports} />
  </>;
};
