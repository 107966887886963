import React from 'react';

import ResizeDetector from 'react-resize-detector';

import './dynamic-column-table.css';

const DynamicTable = ({ items, columns, maxColumnSets = 1, width, tableName = 'dynamic-table' }) => {
  const totalColumnWidths = columns.reduce((total, { width = 50 }) => total + width + 20, 45);
  const possibleColumnSets = typeof width !== 'undefined' && width > 0 ? Math.floor(width / totalColumnWidths) : 1;
  const columnSets = possibleColumnSets > maxColumnSets ? maxColumnSets : (possibleColumnSets || 1);

  const rows = items.reduce((all, current, i) => {
    const x = Math.floor(i / columnSets);

    if (all[x]) {
      all[x].columnSet.push({ ...current, i });
      return all;
    }

    all[x] = {
      x,
      columnSet: [{ ...current, i }],
    }

    return all;
  }, []);

  const hasHeaders = columns.filter(({ heading }) => heading).length > 0;

  return <table className="dynamic-table">
    {hasHeaders ? <thead>
      <tr>
        {rows[0].columnSet.map(({ i, ...item }) => <React.Fragment key={`${tableName}__header__${i}`}>
          <td className="dynamic-table__row__header"></td>
          {columns.map(({ heading }, x) => <th className="dynamic-table__row__header" key={`${tableName}__header__${i}__${x}`}>{heading}</th>)}
        </React.Fragment>)}
      </tr>
    </thead> : <></>}
    <tbody>
      {rows.map(({ columnSet }, rowNumber) => <tr key={`${tableName}__${rowNumber}`} className={rows.length -1 === rowNumber ? 'dynamic-table__last-row' : ''}>
        {columnSet.map(({ i, ...item }) => <React.Fragment key={`${tableName}__${rowNumber}__row__${i}`}>
          <th key={`${tableName}__${rowNumber}__row__${i}__header`} className="dynamic-table__row__header">{i + 1}</th>
          {columns.map(({ value, width, formatter }, columnNumber) => <td
             key={`${tableName}__${rowNumber}__row__${i}__${columnNumber}`}
            className="dynamic-table__row__cell"
            style={{ width: `${width}px` }}>{formatter ? formatter(item[value]) : item[value]}</td>)}
        </React.Fragment >)}
      </tr>)}
    </tbody>
  </table>;
};

export default (props) => {
  return <ResizeDetector handleWidth refreshMode="throttle" refreshRate={250}>
    <DynamicTable {...props} />
  </ResizeDetector>;
};
