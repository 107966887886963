import React from 'react';

export default ({
  dialogs,
  openDialog,
  onClose,
  ...props
}) => {
  if (!openDialog) {
    return <></>;
  }

  return dialogs[openDialog]({
    onClose: () => {
      if (onClose) {
        onClose();
      }
    },
    ...props,
  });
};
