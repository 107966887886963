import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { DateTime } from 'luxon';

import UserRoleIcon from '../icons/user-role-icon';
import { kebabToSentenceCase } from '../../utilities/string';
import QuickFunctions from './quick-functions';
import DialogManager from './dialogs';

import './user-details.css';

const OptionalInfo = ({ value, formatter }) => {
  if (typeof value === 'undefined') {
    return <></>;
  }

  return <div className="user-details__user-info-item">
    {formatter ? formatter(value) : value}
  </div>;
};

export const UserDetailDialogManager = DialogManager;

export const UserDetailQuickFunctions = QuickFunctions;

export const TeamMember = ({
  userInfo: {
    firstName,
    lastName,
    userRole,
    email,
    created,
    lastLogin,
  },
  quickFunctions
}) => <>
  <div className="user-details__user-info-item user-details__user-info-item__name">{firstName} {lastName}</div>
    {quickFunctions}
    <div className="user-details">
      <div className="user-details__user-info-item user-details__user-info-item__user-role"><UserRoleIcon role={userRole} /> {kebabToSentenceCase(userRole)}</div>
      <div className="user-details__user-info-item"><FontAwesomeIcon icon={faEnvelope} /> {email}</div>
      <OptionalInfo value={created} />
      <OptionalInfo value={lastLogin} />
  </div>
</>;

export const InvitedUser = ({
  userInfo: {
    email,
    lastInviteSent,
    created,
  },
  quickFunctions
}) => {
  const expiresOn = lastInviteSent ? DateTime.fromISO(lastInviteSent).plus({ days: 7 }) : undefined;
  const isExpired = expiresOn && expiresOn.diffNow() < 0;

  return <>
    <div className="user-details__user-info-item user-details__user-info-item__name">Invited User{isExpired ? ' (Expired)' : ''}</div>
    {quickFunctions}
    <div className="user-details">
      <div className="user-details__user-info-item"><FontAwesomeIcon icon={faEnvelope} /> {email}</div>
      <OptionalInfo value={created} formatter={(value) => `Created ${DateTime.fromISO(value).toRelative()}`} />
      <OptionalInfo value={lastInviteSent} formatter={(value) => `Last invite sent ${DateTime.fromISO(value).toRelative()}`} />
      <OptionalInfo value={expiresOn} formatter={(value) => {
        const isExpired = value.diffNow() < 0;

        return `${isExpired ? 'Expired' : 'Expires'} ${DateTime.fromISO(value).toRelative()}`;
      }} />
    </div>
  </>;
};
