import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import ResizeDetector from 'react-resize-detector';

import Loading from '../../components/loading';
import ItemList from './item-list';

import './responsive-list.css';

const LoadMoreItems = ({ itemText, moreItemsAvailable, loadingMoreItems, onClick }) => {
  if (!moreItemsAvailable) {
    return <></>;
  }

  if (loadingMoreItems) {
    return <div className="responsive-table__row responsive-table__load-more">
      <Loading />
    </div>;
  }
  
  return <div className="responsive-table__row responsive-table__load-more" onClick={() => onClick()}>
    <div className="responsive-table__load-more__icon"><FontAwesomeIcon icon={faPlusSquare} /></div>
    <div>Load more {itemText}...</div>
  </div>;
};


const getBreakpointClass = (width, columns) => {
  const minimumTableModeWidth = columns.reduce((total, { hide, width }) => total + (hide ? 0 : width), 230);

  if (!width) {
    return '';
  }

  if (width < 575) {
    return 'minimal-mode';
  }

  if (minimumTableModeWidth > width) {
    return 'list-mode';
  }

  return '';
}

const calculateColumnWidth = (width, column) => {
  if (typeof column.width === 'string' && column.width.endsWith('%')) {
    const calculatedWidth = Math.floor(width * (column.width.substring(0, column.width.length - 1) / 100));
    const minWidth = column.minWidth || 90;

    return calculatedWidth > minWidth ? calculatedWidth : minWidth;
  }

  return column.width;
};

const isFixedWidth = ({ width }) => typeof width !== 'string' || !width.endsWith('%');

const calculateColumnWidths = (width, columns) => {
  const totalFixedColumnWidth = columns
    .reduce((total, column) => total + (isFixedWidth(column) ? column.width : 0), 0);

  const remainingWidth = width - totalFixedColumnWidth;
  let totalUsedWidth = 0;

  return columns.map((column) => {
    const { width, minWidth, ...rest } = column;
    const calculatedWidth = calculateColumnWidth(remainingWidth, column);

    if (!isFixedWidth) {
      totalUsedWidth = totalUsedWidth + calculatedWidth;
    }

    return {
      ...rest,
      width: calculatedWidth,
    };
  });
};

const getColumnVisibility = (width, columns) => {
  let totalUsedWidth = columns.reduce((total, { hide, width }) => total + (hide ? 0 : width), 230);
  
  while (totalUsedWidth >= width) {
    const [optionalColumn] = columns.filter(({ required = true, hide }) => !required && !hide);

    if (!optionalColumn) {
      break;
    }

    optionalColumn.hide = true;
    totalUsedWidth = totalUsedWidth - optionalColumn.width;
  }

  return columns;
};

const List = ({
  items,
  moreItemsAvailable = false,
  loadingMoreItems = false,
  showTopBar = true,
  width,
  itemName = 'item',
  events = {},
  columns,
}) => {
  const columnsWithCalculatedWidths = calculateColumnWidths(width, columns);
  const columnsWithVisibility = getColumnVisibility(width, columnsWithCalculatedWidths);
  const responsiveState = getBreakpointClass(width, columnsWithVisibility);
  const ulClassName = `responsive-table${responsiveState ? ` ${responsiveState}` : ''}`;

  const [showInlineLoading, setShowInlineLoading] = useState(false);

  const loadMoreItemsHandler = () => {
    if (events.loadMoreItems) {
      events.loadMoreItems();
    }
  };

  const refreshHandler = () => {
    if (events.refresh) {
      setShowInlineLoading(true);
      events.refresh();
    }
  };

  if (events.onLoadStart) {
    events.onLoadStart(() => {
      setShowInlineLoading(true);
    });
  }

  if (events.onLoadComplete) {
    events.onLoadComplete(() => {
      setShowInlineLoading(false);
    });
  }

  return <>
    {showTopBar
      ? <div className="responsive-table__paging-info">
        {showInlineLoading ? <h3>&nbsp;</h3> : <h3>Showing {items.length} {itemName}{items.length === 1 ? '' : 's'}</h3>}
        <div className="responsive-table__paging-info__refresh">
          <FontAwesomeIcon icon={faSync} onClick={refreshHandler} />
        </div>
      </div>
      : <></>}
    {showInlineLoading
      ? <Loading />
      : <>
        <ItemList listMode={responsiveState} items={items} ulClassName={ulClassName} columns={columnsWithCalculatedWidths} />

        <LoadMoreItems
          itemText={`${itemName}${items.length === 1 ? '' : 's'}`}
          moreItemsAvailable={moreItemsAvailable}
          loadingMoreItems={loadingMoreItems}
          onClick={() => loadMoreItemsHandler()} />
      </> }
  </>;
};

export default (props) => <ResizeDetector handleWidth refreshMode="throttle" refreshRate={250}>
  <List {...props} />
</ResizeDetector>;
