import React, { useState, useEffect } from 'react';

import { Redirect, useHistory } from 'react-router-dom';
import { API } from 'aws-amplify';
import { Form, Formik } from 'formik';

import SingleColumn from '../../components/layouts/single-column';
import SubmitButton from '../../components/button/submit-button';
import StatusMessage from '../../components/form/status-message';
import Loading from '../../components/loading';
import UserRoleIcon from '../../components/icons/user-role-icon';
import QuickFunctions from '../../components/quick-functions';
import DescriptiveSelector from '../../components/descriptive-selector';
import useUserContext from '../../user-context';
import { kebabToSentenceCase } from '../../utilities/string';

import './edit-user.css';

const roles = [
  { role: 'inspector', description: 'Can create and view their own reports, as well as mark their reports for review by team leads/admins.' },
  { role: 'team-lead', description: 'Can create reports, view all team reports and complete reviews.' },
  { role: 'admin', description: 'Ability to add, edit & remove team members, as well as create reports, view all team reports and complete reviews.' },
];

const exceptionPanels = {
  owner: ({ quickFunctions }) => <>
    <h2>Edit User Invitation</h2>
    {quickFunctions}
    <div className="edit-user__invited-user">
      <p>
        Cannot edit the access level of the account owner, make another user Owner first to reduce their access level. 
      </p>
    </div>
  </>,
  invited: ({ quickFunctions, userInfo }) => <>
    <h2>Edit User Invitation</h2>
    {quickFunctions}
    <div className="edit-user__invited-user">
      <p>
        Sorry you can't edit <b>{userInfo.email}</b>'s invitation.
      </p>
      <p>
        A team member must have logged in and set their password before you can edit their details or change their access.  To resend their invitation email return to user details. 
      </p>
    </div>
  </>,
};

const TeamMember = ({ quickFunctions, userInfo }) => {
  const history = useHistory();

  return <>
    <h2>Edit {userInfo.firstName}</h2>
    {quickFunctions}
    <SingleColumn>
      <h3>Access</h3>
      Set user access level, they will need to logout and log back in again for the change to take effect.
      <Formik
        initialValues={{
          userRole: userInfo.userRole,
        }}
        onSubmit={async (values,
          {
            setSubmitting,
            setStatus,
          }) => {
            setSubmitting(true);

            try {
              await API.put('api', `user/${userInfo.username}`, {
                body: values,
              });

              history.push(`/team/member/${userInfo.username}`);
            } catch (e) {
              setSubmitting(false);

              if (e.message === 'Network Error') {
                setStatus({ text: 'Could not contact Server, please try again later', state: 'error' });
                return;
              }

              if (e.response.status === 400) {
                if (!e.response.data || !e.response.data.message) {
                  setStatus({ text: e.message });

                  return;
                }
              }
            }
        }}
        render={({ values, setValues, errors, status = {}, touched, submitForm, isSubmitting }) => {
          return <Form>
            <StatusMessage text={status.text} state={status.state} />
            
            <DescriptiveSelector values={roles.map(({ role, description }) => ({
                icon: <UserRoleIcon role={role} />,
                value: role,
                title: kebabToSentenceCase(role),
                text: description,
              }))}
              defaultValue={userInfo.userRole}
              onChange={(value) => setValues({ userRole: value })} />

            <div className="edit-user__buttons">
              <SubmitButton
                key="edit-user__submit-button"
                text="Edit"
                submitted={isSubmitting} />
            </div>
          </Form>;
          }} />
    </SingleColumn>
  </>;
};

const UserDetails = ({ userId, userContext }) => {
  const [data, updateData] = useState();

  function useFetch(url) {
    useEffect(() => {
      const getData = async () => {
        const resp = await API.get('api', url);
        updateData(resp);
      };
      getData();
    }, [url]);

    return data;
  }

  const userInfo = useFetch(`user/${userId}`);

  if (!userInfo) {
    return <Loading />;
  }

  const quickFunctions = <QuickFunctions buttons={[{ type: 'back' }]} />;

  return <>
    {exceptionPanels[userInfo.userRole]
      ? exceptionPanels[userInfo.userRole]({ userInfo, quickFunctions })
      : <TeamMember userInfo={userInfo} quickFunctions={quickFunctions} />}
  </>;
};

export default ({ match }) => {
  const userId = match.params.id;
  const userContext = useUserContext();

  if(!userContext || !userContext.teamId) {
    return <></>;
  }

  if(!['admin', 'owner', 'system-admin'].includes(userContext.userRole)) {
    return <Redirect to="/403" />;
  }

  return <>
    <UserDetails userId={userId} userContext={userContext} />
  </>;
};