import React, { useState, useEffect } from 'react';

import { Redirect } from 'react-router-dom';
import { API } from 'aws-amplify';

import Loading from '../../components/loading';
import useUserContext from '../../user-context';
import { TeamMember, InvitedUser, UserDetailQuickFunctions, UserDetailDialogManager } from '../../components/user-details';

import './user-details.css';

const UserDetails = ({ userId, userContext }) => {
  const [data, updateData] = useState();
  const [dialogOptions, setDialogOptions] = useState({});
  const [increment, setIncrement] = useState(0);

  function useFetch(url) {
    useEffect(() => {
      const getData = async () => {
        const resp = await API.get('api', url);
        updateData(resp);
        setDialogOptions({ openDialog: undefined })
      };
      getData();
    }, [url]);

    return data;
  }

  const userInfo = useFetch(`user/${userId}?increment=${increment}`);

  if (!userInfo) {
    return <Loading />;
  }

  const quickFunctions = <UserDetailQuickFunctions
    userContext={userContext}
    userInfo={userInfo}
    openDialog={(dialog) => setDialogOptions({ openDialog: dialog })} />;

  return <>
    {userInfo.userRole === 'invited' ? <InvitedUser userInfo={userInfo} quickFunctions={quickFunctions} /> : <TeamMember userInfo={userInfo} quickFunctions={quickFunctions} />}
    
    <UserDetailDialogManager
      userInfo={userInfo}
      onClose={() => setDialogOptions({})}
      refresh={() => setIncrement(increment + 1)}
      {...dialogOptions}
    />
  </>;
};

export default ({ match }) => {
  const userId = match.params.id;
  const userContext = useUserContext();

  if(!userContext || !userContext.teamId) {
    return <></>;
  }

  if(!['admin', 'owner', 'system-admin'].includes(userContext.userRole)) {
    return <Redirect to="/403" />;
  }

  return <>
    <UserDetails userId={userId} userContext={userContext} />
  </>;
};