import React from 'react';

import renderSection from './';
import Button from '../../button';
import { faPlusCircle, faCheck, faTrash, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const formFieldFormatter = {
  checkbox: (value) => (value ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faTimesCircle} />),
  'default': value => value,
}

const InfiniteFormItemValue = ({ column, value }) => {
  const formattedValue = formFieldFormatter[column.type]
    ? formFieldFormatter[column.type](value)
    : formFieldFormatter.default(value);

  return <div>
    <div  className="edit-report__infinite-form__summary__item__row-value__container">
      <div className="edit-report__infinite-form__summary__item__row-value__label">
        {column.label}
      </div>
      <div className="edit-report__infinite-form__summary__item__row-value__value">
        {formattedValue}
      </div>
    </div>
  </div>;
};

const InfiniteFormItemList = ({ report, sections, dispatch }) => {
  console.log(report);
  const columns = sections.map(({ fields }) => fields).flat();

  return <ul className="edit-report__infinite-form__summary__items">
    {Object.keys(report).reduce((matching, key) => {
      if (key.startsWith('values[')) {
        return [...matching, report[key]];
      }
  
      return matching;
    }, []).map((row, i) => {
      return <li className="edit-report__infinite-form__summary__item">
        <div className="edit-report__infinite-form__summary__item__row-number">{i + 1}</div>
        <div className="edit-report__infinite-form__summary__item__row-values">
          {columns.map((column) => {
            return <InfiniteFormItemValue column={column} value={row[column.name]} />;
          })}
        </div>
        <div className="edit-report__infinite-form__summary__item__row-buttons">
          <Button icon={faEdit} onClick={() => dispatch({ event: 'edit', row: i })}>Edit</Button>
          <Button icon={faTrash} onClick={() => dispatch({ event: 'delete', row: i })}>Delete</Button>
        </div>
      </li>;
    })}
  </ul>;
};

export default ({ itemTitle, sections = [], report = {}, errors = {}, touched = {}, submitted, dispatch, events }) => {
  const currentItem = report.current || 0;

  const rowDispatch = ({ event, row }) => {
    if (event === 'edit') {
      dispatch({
        name: 'current',
        value: row,
      });

      dispatch({
        name: 'summaryMode',
        value: false,
      });

      dispatch({
        name: 'hideAddAnother',
        value: true,
      });
    }

    if (event === 'delete') {
      dispatch({
        name: 'deleteRow',
        value: row,
      });
    }
  };

  const addAnother = () => {
    const name = `values[${currentItem}]`;

    dispatch({
      event: 'touched',
      name,
    });

    dispatch({
      name: 'current',
      value: currentItem + 1,
      ifValid: name,
    });
  };

  const complete = () => {
    events.panelCompleted();
  };

  const done = () => {
    // dispatch({
    //   event: 'touched',
    //   name: `values[${currentItem}]`,
    // });

    dispatch({
      name: 'summaryMode',
      value: true,
    });

    dispatch({
      name: 'markedDone',
      value: true,
    });

    dispatch({
      name: 'hideAddAnother',
      value: false,
    });
  };

  const editMode = () => {
    dispatch({
      name: 'summaryMode',
      value: false,
    });
  };

  const sectionDispatch = (incoming) => {
    const { event, name, value, ...rest } = incoming;

    if (event === 'touched') {
      dispatch({
        event,
        name: [
          `values[${currentItem}].${name}`,
        ],
      });
    }

    if (event) {
      return dispatch(incoming);
    }

    dispatch({
      name: `values[${currentItem}].${name}`,
      value,
      ...rest,
    });
  };

  const values = report[`values[${currentItem}]`] || {};

  const getErrorsAndTouched = (errors, touched, currentItem) => {
    const mappedErrors = Object.fromEntries(Object.entries(errors)
      .map(([key, value]) => [
        key
          .replace(`outlets.`, '')
          .replace(`values[${currentItem}].`, ''),
        value,
      ]));
    const mappedTouched = Object.fromEntries(Object.entries(touched)
      .map(([key, value]) => [
        key
          .replace(`outlets.`, '')
          .replace(`values[${currentItem}].`, ''),
        value,
      ]));

    return {
      errors: mappedErrors,
      touched: mappedTouched,
      submitted: Object.keys(mappedTouched).includes(`values[${currentItem}]`),
    };
  };

  if (report.summaryMode) {
    return <div className="edit-report__infinite-form">
      <InfiniteFormItemList report={report} sections={sections} dispatch={rowDispatch} />
      <div className="edit-report__infinite-form__buttons">
        <Button icon={faPlusCircle} onClick={editMode}>Add More</Button>
        <Button className="submit" icon={faCheck} onClick={complete}>Section Complete</Button>
      </div>
    </div>;
  }

  return <div className="edit-report__infinite-form">
    <h3>{itemTitle} {currentItem + 1}</h3>
    {sections.map((section) => renderSection(section, {
      dispatch: sectionDispatch,
      ...getErrorsAndTouched(errors, touched, currentItem),
      report: values,
    }))}
    <div className="edit-report__infinite-form__buttons">
      {report.hideAddAnother ? <>
        <Button icon={faCheck} onClick={done}>Update</Button>
      </> : <>
        <Button icon={faPlusCircle} onClick={addAnother}>Add Another</Button>
        <Button icon={faCheck} onClick={done}>Done</Button>
      </>}
      
    </div>
  </div>;
};
