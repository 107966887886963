import React, { useEffect, useState } from 'react';
import { API } from "aws-amplify";
import { Redirect } from 'react-router-dom';
import { faLevelUpAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { DateTime } from 'luxon';

import UserRoleIcon from '../../components/icons/user-role-icon';
import ResponsiveList from '../../components/responsive-list';
import QuickFunctions from '../../components/quick-functions';
import Loading from '../../components/loading';
import useUserContext from '../../user-context';

import './members.css';

const TeamMemberList = ({ userContext: { teamId } }) => {
  const [data, updateData] = useState();
  let onLoadCompleteFunction;

  function useFetch(url) {
    useEffect(() => {
      const getData = async () => {
        const resp = await API.get('api', url);
        updateData(resp);
        setLoading(false);

        if (onLoadCompleteFunction) {
          onLoadCompleteFunction();
        }
      };
      getData();
    }, [url]);

    return data;
  }

  const [loading, setLoading] = useState(true);
  const [increment, setIncrement] = useState(0);

  const users = useFetch(`team/${teamId}/members?userRole=invited&increment=${increment}`);

  const refresh = () => {
    setIncrement(increment + 1);
  };

  const onLoadComplete = (newOnLoadCompleteFunction) => {
    onLoadCompleteFunction = newOnLoadCompleteFunction;
  };

  if (loading || !users) {
    return <div className="loading-wrapper">
      <Loading height={100} />
    </div>;
  }

  const columns = [
    { header: 'Email', isHeader: true, width: 450, value: 'email' },
    {
      header: 'Last Invite Sent',
      width: '25%',
      minWidth: 90,
      value: ({ lastInviteSent }) => {
        if (!lastInviteSent) {
          return ' ';
        }

        return `Invite Sent ${DateTime.fromISO(lastInviteSent).toRelative()}`;
      }
    },
    {
      header: 'Created',
      width: '25%',
      minWidth: 90,
      required: false,
      value: ({ created }) => {
        if (!created) {
          return 'Created Date Unknown';
        }

        return `Created ${DateTime.fromISO(created).toRelative()}`;
      },
    },
  ];

  return <ResponsiveList
    items={users.teamMembers.map(user => ({
      key: user.username,
      link: `/team/member/${user.username}`,
      icon: <UserRoleIcon role={user.userRole} />,
      ...user,
    }))}
    columns={columns}
    itemName="invitation"
    events={{ refresh, onLoadComplete }}
  />;
};

export default () => {
  const userContext = useUserContext();

  if(!userContext || !userContext.teamId) {
    return <></>;
  }

  if(!['admin', 'owner', 'system-admin'].includes(userContext.userRole)) {
    return <Redirect to="/403" />;
  }

  return <>
    <h2>Manage your Team - Invitations</h2>

    <QuickFunctions buttons={[
      { icon: faLevelUpAlt, text: 'Back to Team Management', link: '/team' },
      { icon: faUserPlus, text: 'Invite Team Member', link: '/team/invite' },
    ]} />

    <TeamMemberList userContext={userContext} />
  </>;
};
